<template>
    <div>
        <div class="grid place-items-center">
            <a-button class="align-middle flex p-5 text-[#5C5C5C] mt-5" style="width: 100%;height: 52px;"
                v-on:click="add">
                <a-icon type="plus-circle" theme="filled" style="font-size: 30px;margin-top: -10px;color: #3b82f6;" />
                Adicionar producto y motivo
            </a-button>
        </div>
    </div>
</template>


<script>

export default {
    methods: {
        add() {
            this.$emit('click')
        },
    }
};
</script>
