<template>
  <div class="w-full mt-10 mb-10">
    <Accordion
      :open="open"
      :disable="!open"
      key="step-1.1"
      header="1.3 Relacionamiento entre la entidad y el defensor"
    >
      <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
        1.3.1 Reporte reincidencias
      </h2>

      <BaseRadio
        ref="step_131"
        name="step_131"
        label="Se presentaron situaciones de incumplimiento parcial o total por parte de la entidad con posterioridad a los ajustes o a la rectificación en el período reportado"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'step_131')"
        :radioOptions="options.oYesOrNot"
        required="true"
        class="mt-10 mb-8"
        v-model="
          mainData.step_1_3
            ._1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificación_reportado
        "
        :disabled="soloPreview"
      />

      <div
        v-if="
          mainData.step_1_3
            ._1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificación_reportado ==
          1
        "
      >
        <BaseInput
          ref="Número total de reincidencias registradas"
          type="number"
          label="Número total de reincidencias"
          required="true"
          v-model="
            mainData.step_1_3._1_3_1_total_decision_emitida_favor_entidad
          "
          :disabled="soloPreview"
        />

        <BaseTextarea
          class="col-start-1 col-span-2"
          ref="describe_1.2.2.2"
          id="describe_1.2.2.2"
          label="Presente y describa las situaciones, productos y tipologías de quejas en las cuales en mayor medida se presentan reincidencias por parte de la entidad"
          placeholder=""
          maxlength="2000"
          required="true"
          v-model="
            mainData.step_1_3
              ._1_3_1_tipologia_describa_situaciones_DCF_considere_incumplimiento
          "
          :disabled="soloPreview"
        />

        <BaseTextarea
          class="mt-10"
          ref="describe_1.2.52"
          id="describe_1.2.52"
          label="Describa las situaciones que la DCF considere de incumplimiento (parcial o total) por parte de la entidad vigilada después de haber efectuado, ajustes o rectificaciones, sin hacer referencia a casos particulares."
          placeholder=""
          maxlength="500"
          v-model="
            mainData.step_1_3
              ._1_3_1_describa_situaciones_DCF_considere_incumplimiento
          "
          :disabled="soloPreview"
        />
      </div>

      <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
        1.3.2 Reporte de situaciones relevantes
      </h2>

      <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
        1.3.2.1 Casos en que la entidad consideró que el DCF carecía de
        competencia
      </h2>

      <BaseRadio
        ref="step_1321"
        name="step_1321"
        label="¿Se presentaron casos en los que la entidad consideró que el DCF carecía de competencia?"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'step_1321')"
        :radioOptions="options.oYesOrNot"
        required="true"
        class="mt-20 mb-10"
        v-model="
          mainData.step_1_3
            ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia
        "
        :disabled="soloPreview"
      />

      <!-- TO_DO: VAlidar si las condicionales de los siguiente componentes están bien-->
      <BaseInput
        v-if="
          mainData.step_1_3
            ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
          1
        "
        ref="total_casos_entidad_considero_DCF_no_competente"
        label="Número total de casos en los que la entidad consideró que el DCF no era competente"
        :type="'number'"
        :numeric="true"
        class="w-50 mt-10"
        v-model="
          mainData.step_1_3
            ._1_3_2_1_total_casos_entidad_considero_DCF_no_competente
        "
        :disabled="soloPreview"
      />

      <BaseRadio
        v-if="
          mainData.step_1_3
            ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
          1
        "
        ref="step_13211"
        name="step_13211"
        label="¿La defensoría estuvo de acuerdo con la totalidad de los casos en los cuales la entidad justificó causales de no competencia para la DCF?"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'step_13211')"
        :radioOptions="options.oYesOrNot"
        required="true"
        class="mt-10 mb-10"
        v-model="
          mainData.step_1_3
            ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia
        "
        :disabled="soloPreview"
      />

      <BaseTextarea
        v-if="
          !(
            mainData.step_1_3
              ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
            1
          )
        "
        class="mt-10"
        ref="describe_1.3.211"
        id="describe_1.3.211"
        label="Describa brevemente las razones por las cuales el DCF no compartió la posición de la entidad sobre su competencia. Por favor no incluir casos ni quejas individuales."
        placeholder=""
        maxlength="400"
        v-model="
          mainData.step_1_3
            ._1_3_2_1_describa_DCF_no_compartio_entidad_competencia_no_casos_ni_quejas_individuales
        "
        :disabled="soloPreview"
      />

      <BaseTextarea
        v-if="
          !(
            mainData.step_1_3
              ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
            1
          )
        "
        class="mt-10"
        ref="describe_1.3.211"
        id="describe_1.3.211"
        label="Indique si el DCF reportó o expresó sus consideraciones a la entidad y de qué forma lo hizo (Detalle la información de fechas y área(s) de la entidad a la cual se le hizo el reporte)"
        placeholder=""
        maxlength="400"
        v-model="
          mainData.step_1_3
            ._1_3_2_1_indique_DCF_reporto_consideraciones_entidad_de_que_forma_hizo
        "
        :disabled="soloPreview"
      />

      <BaseInput
        v-if="
          !(
            mainData.step_1_3
              ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
            1
          )
            ? false
            : mainData.step_1_3
                ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
              1
        "
        ref="total_quejas_asociadas_justificacion"
        type="text"
        label="Número total de quejas asociadas a la justificación"
        :required="true"
        :type="'number'"
        :numeric="true"
        v-model="
          mainData.step_1_3._1_3_2_1_total_quejas_asociadas_justificacion
        "
        :disabled="soloPreview"
      />

      <BaseCheckboxVertical
        v-if="
          !(
            mainData.step_1_3
              ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
            1
          )
            ? false
            : mainData.step_1_3
                ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
              1
        "
        class="col-start-1 col-span-4 mb-10"
        label="Justificación de la no competencia según la entidad"
        :value="
          mainData.step_1_3
            ._1_3_2_1_justificacion_no_competencia_segun_entidad_ids
        "
        @notify-changes="onChangeSelectJustInadmision"
        :data="options.oJustInadmision"
        :disabled="soloPreview"
      />

      <div class="col-span-2"></div>

      <div
        v-if="
          mainData.step_1_3
            ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
          2
            ? false
            : mainData.step_1_3
                ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
              1
        "
      >
        <a-card
          v-if="isJustInadActive('1')"
          size="small"
          class="text-left col-span-2"
          title="1) Causal legal de exclusión de competencia considerada por la entidad"
        >
          <a-button
            slot="extra"
            type="link"
            @click="
              openAccordions.showJustInad1 = !openAccordions.showJustInad1
            "
          >
            Ver detalles
            <a-icon :type="openAccordions.showJustInad1 ? 'up' : 'down'" />
          </a-button>

          <div v-if="openAccordions.showJustInad1">
            <BaseNumber
              v-model="
                mainData.step_1_3._1_3_2_1_causal_legal_numero_total_solicitudes
              "
              ref="num_12__numero_total_solicitudes"
              label="Número total de inadmitidas asociadas a la justificación seleccionada."
              placeholder="Ej: 123"
              :disabled="soloPreview"
            />

            <a-collapse class="mt-6">
              <a-collapse-panel key="1" header="Causales">
                <p
                  slot="extra"
                  class="text-opacity-80"
                  :class="
                    mainData.step_1_3
                      ._1_3_2_1_causal_legal_numero_total_solicitudes !=
                    totalCausales_1_3_2_1
                      ? 'text-red-500'
                      : 'text-green-500'
                  "
                >
                  Total: {{ totalCausales_1_3_2_1 }}
                  {{
                    mainData.step_1_3
                      ._1_3_2_1_causal_legal_numero_total_solicitudes !=
                    totalCausales_1_3_2_1
                      ? "(No coinciden los totales)"
                      : ""
                  }}
                </p>
                <a-table
                  size="small"
                  :pagination="false"
                  :row-selection="rowSelection"
                  :columns="columnsCausal"
                  :data-source="options.oTipoJustInCausaLegal"
                >
                  <template slot="labelName" slot-scope="text, record">
                    <a class="flex text-left">{{ text }}</a>
                  </template>
                  <template class="p-0" slot="count" slot-scope="text, record">
                    <BaseNumber
                      v-if="isRowSelected(record.id)"
                      :value="valueCausal(record.id)"
                      @notify-change="updateDetail(record.id, $event)"
                      label="Digita una cantidad"
                      ref="num_31_numero_total_solicitudes"
                      class="col-start-1 col-span-2 mt-0 pt-0"
                      placeholder="Ej: 123"
                      :mt_disabled="true"
                      :disabled="soloPreview"
                    />
                  </template>
                </a-table>
              </a-collapse-panel>
            </a-collapse>

            <BaseRadio
              v-model="
                mainData.step_1_3._1_3_2_1_causal_legal_aplica_producto_motivo
              "
              ref="opt_causal_legal_aplica_producto_motivo"
              class="col-start-1 col-span-2"
              label="¿Aplica producto y motivo?"
              :radioOptions="options.oYesOrNot"
              :disabled="soloPreview"
            />

            <BaseTextarea
              v-if="causalLegalIncludeOtra()"
              v-model="mainData.step_1_3._1_3_2_1_causal_legal_otra_descripcion"
              class="col-start-1 col-span-4"
              ref="describe"
              id="describe"
              label="Descripción"
              placeholder="Mensaje"
              maxlength="50"
              :disabled="soloPreview"
            />

            <ProductReasonsWithTableField
              ref="pr_table_1_3_2_1"
              name="pr_table_1_3_2_1"
              :company_id="company.company_id"
              v-if="
                mainData.step_1_3
                  ._1_3_2_1_causal_legal_aplica_producto_motivo == 1
              "
              :labelReasonsCounter="'Número de queja de la no competencia según motivo seleccionado'"
              :labelProductCounter="'Número de queja de la no competencia según producto seleccionado'"
              :products_reasons.sync="
                mainData.step_1_3._1_3_2_1_producto_motivo
              "
              :hideTopInputs="true"
              :soloPreview="soloPreview"
            />

            <BaseTextarea
              v-if="
                mainData.step_1_3
                  ._1_3_2_1_justificacion_inadmision_causal_legal_aplica_producto_motivo
              "
              v-model="mainData.step_1_3._1_3_2_1_descripcion"
              class="col-start-1 col-span-4"
              ref="describe"
              id="describe"
              label="Descripción"
              placeholder="Mensaje"
              maxlength="1000"
              :disabled="soloPreview"
            />
          </div>
        </a-card>
        <a-card
          v-if="isJustInadActive('2')"
          size="small"
          class="text-left col-span-2"
          title="2) Se refiere a otra entidad vigilada"
        >
          <a-button
            slot="extra"
            type="link"
            @click="
              openAccordions.showJustInad2 = !openAccordions.showJustInad2
            "
          >
            Ver detalles
            <a-icon :type="openAccordions.showJustInad2 ? 'up' : 'down'" />
          </a-button>
          <div v-if="openAccordions.showJustInad2">
            <BaseNumber
              v-model="
                mainData.step_1_3
                  ._1_3_2_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes
              "
              ref="num_13_numero_total_solicitudes"
              label="Número total inadmitidas asociadas a la justificación seleccionada."
              class="col-start-1 col-span-2"
              placeholder="Ej: 123"
              :disabled="soloPreview"
            />
          </div>
        </a-card>
        <a-card
          v-if="isJustInadActive('3')"
          size="small"
          class="text-left col-span-2"
          title="3) Solicitud incompleta, no clara, lenguaje soez o agresivo"
        >
          <a-button
            slot="extra"
            type="link"
            @click="
              openAccordions.showJustInad3 = !openAccordions.showJustInad3
            "
          >
            Ver detalles
            <a-icon :type="openAccordions.showJustInad3 ? 'up' : 'down'" />
          </a-button>
          <div v-if="openAccordions.showJustInad3">
            <BaseNumber
              v-model="
                mainData.step_1_3
                  ._1_3_2_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes
              "
              ref="num_12_numero_total_solicitudes"
              label="Número total inadmitidas asociadas a la justificación seleccionada."
              class="col-start-1 col-span-2"
              placeholder="Ej: 123"
              :disabled="soloPreview"
            />
          </div>
        </a-card>
        <a-card
          v-if="isJustInadActive('4')"
          size="small"
          class="text-left col-span-2"
          title="4) Otra"
        >
          <a-button
            slot="extra"
            type="link"
            @click="
              openAccordions.showJustInad4 = !openAccordions.showJustInad4
            "
          >
            Ver detalles
            <a-icon :type="openAccordions.showJustInad4 ? 'up' : 'down'" />
          </a-button>
          <div v-if="openAccordions.showJustInad4">
            <BaseNumber
              v-model="
                mainData.step_1_3
                  ._1_3_2_1_justificacion_inadmision_otra_numero_total_solicitudes
              "
              ref="num_12_numero_total_solicitudes"
              label="Número total de solicitudes de conciliación inadmitidas asociadas a la justificación seleccionada."
              class="col-start-1 col-span-2"
              placeholder="Ej: 123"
              :disabled="soloPreview"
            />
            <BaseTextarea
              v-model="
                mainData.step_1_3
                  ._1_3_2_1_justificacion_inadmision_otra_descripcion
              "
              class="col-start-1 col-span-4"
              ref="describe"
              id="describe"
              label="Informe brevemente y de manera consolidada las principales temáticas de las solicitudes de conciliación que fueron inadmitidas por la DCF. (Por favor no incluir casos individuales)"
              placeholder="Escribe la descripción..."
              maxlength="50"
              :disabled="soloPreview"
            />
          </div>
        </a-card>
      </div>

      <BaseTextarea
        v-if="
          mainData.step_1_3
            ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
          1
        "
        class="mt-10 col-start-1 col-span-2"
        ref="describe_step_13211_1_mp"
        id="describe_step_13211_1_mp"
        label="Resuma las situaciones en las que la EV consideró que la DCF no era competente, priorizando las más representativas. No incluir casos particulares."
        placeholder=""
        maxlength="1000"
        v-model="
          mainData.step_1_3
            ._1_3_2_1_resuma_situaciones_EV_considero_DCF_no_competente
        "
        :disabled="soloPreview"
      />

      <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
        1.3.2.2 Colaboración de la entidad con el DCF
      </h2>

      <BaseRadio
        ref="entidad_colaboró_suministró_información"
        name="entidad_colaboró_suministró_información"
        label="¿La entidad colaboró con el DCF o suministró la información requerida?"
        class="mt-10 mb-10"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'step_1322')"
        :radioOptions="options.oYesOrNot"
        required="true"
        v-model="
          mainData.step_1_3
            ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida
        "
        :disabled="soloPreview"
      />

      <BaseInput
        v-if="
          mainData.step_1_3
            ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida == 2
        "
        ref="total_entidad_no_suministró_información"
        type="text"
        label="Número total de eventos en las que la entidad no suministró la información requerida por la DCF"
        :required="true"
        class="mb-10"
        v-model="
          mainData.step_1_3
            ._1_3_2_2_total_eventos_entidad_no_suministro_informacion_requerida_DCF
        "
        :disabled="soloPreview"
      />

      <ProductReasonsWithTableField
        ref="pr_table_1_3_2_2"
        name="pr_table_1_3_2_2"
        :company_id="company.company_id"
        v-if="
          mainData.step_1_3
            ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida == 2
        "
        :labelReasonsCounter="'Número total de eventos en las que la entidad no suministró la información requerida por la DCF según motivo seleccionado'"
        :labelProductCounter="'Número total de eventos en las que la entidad no suministró la información requerida por la DCF según producto seleccionado'"
        :products_reasons.sync="mainData.step_1_3._1_3_2_2_producto_motivo"
        :hideTopInputs="true"
        :soloPreview="soloPreview"
      />

      <BaseTextarea
        v-if="
          mainData.step_1_3
            ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida == 2
        "
        class="mt-10 col-start-1 col-span-2"
        ref="describe_step_1322"
        id="describe_step_1322"
        label="Resuma de manera consolidada las situaciones presentadas para que el Defensor considere que la entidad no colaboró con la Defensoría, priorizando aquellas más representativas y/o de mayor materialidad o impacto en el consumidor financiero. Por favor no incluir casos ni quejas individuales."
        placeholder=""
        maxlength="1000"
        v-model="
          mainData.step_1_3
            ._1_3_2_2_resuma_situaciones_defensor_entidad_no_colaboro_con_defensoria
        "
        :disabled="soloPreview"
      />

      <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
        1.3.2.3 Aceptación de los pronunciamientos del DCF a favor del
        consumidor financiero, por parte de la entidad
      </h2>

      <BaseRadio
        ref="DCF_permitio_pronunciamiento_a_favor_consumidor_financiero"
        name="DCF_permitio_pronunciamiento_a_favor_consumidor_financiero"
        label="¿El DCF emitió pronunciamientos a favor del consumidor financiero?"
        class="mt-10 mb-10"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'step_1323')"
        :radioOptions="options.oYesOrNot"
        required="true"
        v-model="
          mainData.step_1_3
            ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general
        "
        :disabled="soloPreview"
      />

      <BaseInput
        v-if="
          mainData.step_1_3
            ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          1
        "
        ref="total_decisiones_emitidas_a_favor_consumidor_financiero"
        label="Número total de decisiones emitidas a favor del consumidor financiero"
        :required="true"
        :type="'number'"
        :numeric="true"
        class="mb-10"
        v-model="
          mainData.step_1_3
            ._1_3_2_3_total_decisiones_emitidas_a_favor_consumidor_financiero
        "
        :disabled="soloPreview"
      />

      <p v-if="equalTotalEmitter" class="text-xs text-red-500">
        El número total de decisiones emitidas a favor del consumidor financiero
        (1.3.2.3) no coincide con la suma de las categorías "totalmente a favor"
        (1.2.5.1) y "parcialmente a favor" (1.2.5.2) . Por favor, revise los
        datos ingresados.”
      </p>

      <BaseCheckboxDisable
        v-if="
          mainData.step_1_3
            ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          1
        "
        :options="options.checkOptions"
        label="¿Los pronunciamientos del DCF son obligatorios para la entidad?"
        :showAll="false"
        @input="onChangeCheck"
        :disabled="soloPreview"
      />

      <div
        v-if="
          mainData.step_1_3
            ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          1
        "
      >
        <div v-if="isChecks(1)">
          <a-table
            :columns="columns"
            :data-source="mainData.step_1_2._1_2_4_1_producto_motivo"
            :pagination="false"
            :scroll="{ x: '1220px' }"
            :ref="'step_1323_1'"
          >
            <div
              slot="fecha_reporte"
              class="title-size font-bold text-gray-500 text-center"
            >
              <p>Producto</p>
            </div>
            <div
              slot="entidad_vigilada"
              class="title-size font-bold text-gray-500"
            >
              N°
            </div>
          </a-table>

          <div class="grid grid-cols-2 gap-x-8 mb-10">
            <BaseInput
              ref="decisiones_totalmente_favorables_consumidor_financiero"
              label="Número de decisiones totalmente  favorables al consumidor financiero."
              :type="'number'"
              :numeric="true"
              class="mb-10"
              :disabled="true"
              :value="
                mainData.step_1_2
                  ._1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero
              "
            />

            <BaseInput
              ref="decisiones_parcialmente_favorables_consumidor_financiero"
              label="Número total de decisiones parcialmente favorables al consumidor financiero"
              :type="'number'"
              :numeric="true"
              class="mb-10"
              :disabled="true"
              :value="
                mainData.step_1_2
                  ._1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero
              "
            />
          </div>
        </div>

        <div v-if="isChecks(2)">
          <BaseInput
            ref="total_pronunciamientos_obligatorios_acuerdo_previo_expreso"
            label="Número total de pronunciamientos obligatorios por acuerdo previo y expreso"
            :type="'number'"
            :numeric="true"
            class="mb-10 w-50"
            v-model="
              mainData.step_1_3
                ._1_3_2_3_2_total_pronunciamientos_obligatorios_acuerdo_previo_expreso
            "
            :disabled="soloPreview"
          />

          <ProductReasonsWithTableField
            ref="pr_table_1_3_2_1_1_1"
            name="pr_table_1_3_2_1_1_1"
            :company_id="company.company_id"
            :labelReasonsCounter="'Número total pronunciamientos obligatorios por acuerdo previo y expreso según motivo seleccionado'"
            :labelProductCounter="'Número total pronunciamientos obligatorios por acuerdo previo y expreso según  producto seleccionado'"
            :products_reasons.sync="
              mainData.step_1_3._1_3_2_1_1_1_producto_motivo
            "
            :hideTopInputs="true"
            :soloPreview="soloPreview"
          />
        </div>

        <div v-if="isChecks(3)">
          <BaseInput
            ref="total_pronunciamientos_obligatorios_segun_criterio"
            label="Número total de pronunciamientos obligatorios según criterio"
            :type="'number'"
            :numeric="true"
            class="mb-10 w-50"
            v-model="
              mainData.step_1_3
                ._1_3_2_3_3_total_pronunciamientos_obligatorios_segun_criterio
            "
            :disabled="soloPreview"
          />

          <BaseTextarea
            class="mt-10 col-start-1 col-span-2"
            ref="informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF"
            id="informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF"
            label="Informe los criterios establecidos por la entidad para aceptar como obligatorias las decisiones a favor del consumidor financiero emitidas por la DCF, tales como producto, motivo, cuantía, tipo de consumidor financiero o cualquier otra consideración"
            placeholder=""
            maxlength="2000"
            v-model="
              mainData.step_1_3
                ._1_3_2_3_3_informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF
            "
            :disabled="soloPreview"
          />
        </div>

        <div v-if="isChecks(4)">
          <div class="w-50">
            <BaseInput
              ref="total_pronunciamientos_no_obligatorios"
              label="Número total de pronunciamientos no obligatorios"
              :type="'number'"
              :numeric="true"
              class="mb-10 w-50"
              v-model="
                mainData.step_1_3
                  ._1_3_2_3_4_total_pronunciamientos_no_obligatorios
              "
              :disabled="soloPreview"
            />
          </div>
        </div>

        <BaseRadio
          ref="entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor"
          name="entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor"
          label="¿La entidad le informó a la DCF sobre la aceptación o no de las decisiones del defensor a favor del consumidor?"
          class="mt-10 mb-10"
          @onChangeValueGetValue="
            (value) => onChangeRadio(value, 'step_1323_r')
          "
          :radioOptions="options.oYesOrNot"
          required="true"
          v-model="
            mainData.step_1_3
              ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor
          "
          :disabled="soloPreview"
        />

        <BaseTextarea
          v-if="
            !(
              mainData.step_1_3
                ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor ==
              1
            )
          "
          class="mt-10 col-start-1 col-span-2"
          ref="describe_step_1323_no_informo"
          id="describe_step_1323-no_informo"
          label="Reporte brevemente qué tipo de actuaciones desplegó el DCF ante la omisión de información por parte de la entidad y, señale el estado actual de la interacción que sobre el particular se tiene con la entidad. (Por favor no incluir casos ni quejas individuales)"
          placeholder=""
          maxlength="1000"
          v-model="
            mainData.step_1_3
              ._1_3_2_3_reporte_actuaciones_desplego_DCF_omision_informacion_entidad
          "
          :disabled="soloPreview"
        />

        <BaseRadio
          v-if="
            mainData.step_1_3
              ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor ==
            1
          "
          ref="tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad"
          name="tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad"
          label="¿Se tienen decisiones a favor del consumidor no aceptadas por la entidad?"
          class="mt-10 mb-10"
          @onChangeValueGetValue="
            (value) => onChangeRadio(value, 'step_1323_tn')
          "
          :radioOptions="options.oYesOrNot"
          required="true"
          v-model="
            mainData.step_1_3
              ._1_3_2_3_tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad
          "
          :disabled="soloPreview"
        />

        <div
          v-if="
            mainData.step_1_3
              ._1_3_2_3_tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad ==
            1
          "
        >
          <!-- Validación suma de campos -->
          <!-- <p class="text-xs text-red-500">Este campo es obligatorio</p> -->
          <ProductReasonsWithTableField
            ref="pr_table_1_3_2_3_tn"
            name="pr_table_1_3_2_3_tn"
            :company_id="company.company_id"
            :labelTotalCounter="'Número de decisiones a favor del consumidor no aceptadas por la entidad'"
            :labelReasonsCounter="'Número decisiones a favor del consumidor no aceptadas por la entidad según el motivo seleccionado'"
            :labelProductCounter="'Número decisiones a favor del consumidor no aceptadas por la entidad según el producto seleccionado'"
            :products_reasons.sync="
              mainData.step_1_3._1_3_2_3_tn_producto_motivo
            "
            :total-counter.sync="
              mainData.step_1_3._1_3_2_3_tn_producto_motivo_counter
            "
            :hideInstanceCounter="true"
            :soloPreview="soloPreview"
          />

          <BaseTextarea
            class="mt-10 col-start-1 col-span-2"
            ref="describe_step_1323_tn"
            id="describe_step_1323-tn"
            label="Emita breve concepto sobre los pronunciamientos a favor del consumidor financiero no aceptados por la entidad, conforme al impacto generado en los clientes, usuarios y/o potenciales clientes, de cara al riesgo de conductas. (Por favor no incluir casos ni quejas individuales) "
            placeholder=""
            maxlength="1000"
            :disabled="soloPreview"
            v-model="
              mainData.step_1_3
                ._1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_no_aceptados_entidad
            "
          />
        </div>

        <BaseRadio
          v-if="
            mainData.step_1_3
              ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor ==
            1
          "
          ref=" tiene_decisiones_a_favor_consumidor_aceptadas_entidad"
          name="tiene_decisiones_a_favor_consumidor_aceptadas_entidad"
          label="¿Se tienen decisiones a favor del consumidor aceptadas por la entidad? "
          class="mt-10 mb-10"
          @onChangeValueGetValue="
            (value) => onChangeRadio(value, 'step_1323_t')
          "
          :radioOptions="options.oYesOrNot"
          required="true"
          :disabled="soloPreview"
          v-model="
            mainData.step_1_3
              ._1_3_2_3_tiene_decisiones_a_favor_consumidor_aceptadas_entidad
          "
        />

        <div
          v-if="
            mainData.step_1_3
              ._1_3_2_3_tiene_decisiones_a_favor_consumidor_aceptadas_entidad ==
            1
          "
        >
          <ProductReasonsWithTableField
            ref="pr_table_1_3_2_3_t"
            name="pr_table_1_3_2_3_t"
            :company_id="company.company_id"
            :labelTotalCounter="'Número decisiones a favor del consumidor aceptadas'"
            :labelReasonsCounter="'Número decisiones a favor del consumidor aceptadas por la entidad según el motivo seleccionado'"
            :labelProductCounter="'Número decisiones a favor del consumidor aceptadas por la entidad según el producto seleccionado'"
            :instance-counter.sync="
              mainData.step_1_3._1_3_2_3_t_instance_counter
            "
            :products_reasons.sync="
              mainData.step_1_3._1_3_2_3_t_producto_motivo
            "
            :hideInstanceCounter="true"
            :total-counter.sync="
              mainData.step_1_3._1_3_2_3_t_producto_motivo_counter
            "
            :soloPreview="soloPreview"
          />

          <BaseTextarea
            class="mt-10 col-start-1 col-span-2"
            ref="describe_step_1323_t"
            id="describe_step_1323-t"
            label="Emita breve concepto sobre los pronunciamientos a favor del consumidor financiero aceptados por la entidad, conforme al impacto generado en los clientes, usuarios y/o potenciales clientes. (Por favor no incluir casos ni quejas individuales.)"
            placeholder=""
            maxlength="1000"
            :disabled="soloPreview"
            v-model="
              mainData.step_1_3
                ._1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_aceptados_entidad
            "
          />
        </div>
      </div>

      <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
        1.3.2.4 Pronunciamientos del DCF de interés general
      </h2>

      <BaseRadio
        ref="período_reportado_emitió_pronunciamientos_interés_general"
        name="período_reportado_emitió_pronunciamientos_interés_general"
        label="¿En el período reportado, la DCF emitió pronunciamientos de interés general?"
        class="mt-10 mb-10"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'step_1324')"
        :radioOptions="options.oYesOrNot"
        required="true"
        :disabled="soloPreview"
        v-model="
          mainData.step_1_3
            ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general
        "
      />

      <BaseTextarea
        v-if="
          mainData.step_1_3
            ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          2
        "
        class="mt-10"
        ref="describe_1.2.52"
        id="describe_1.2.52"
        label="Describa por qué la DCF no emitió pronunciamientos de interés general."
        placeholder=""
        maxlength="500"
        v-model="
          mainData.step_1_3._1_3_1_describa_situaciones_no_interes_general
        "
        :disabled="soloPreview"
      />

      <BaseInput
        v-if="
          mainData.step_1_3
            ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          1
        "
        ref="total_pronunciamientos_interés_general_emitidos_DCF"
        label="Número total de pronunciamientos de interés general emitidos por el DCF"
        :required="true"
        :type="'number'"
        :numeric="true"
        class="mb-10"
        :disabled="soloPreview"
        v-model="
          mainData.step_1_3
            ._1_3_2_4_total_pronunciamientos_interes_general_emitidos_DCF
        "
      />

      <ProductReasonsWithTableField
        ref="pr_table_1_2_1_2"
        name="pr_table_1_2_1_2"
        :company_id="company.company_id"
        v-if="
          mainData.step_1_3
            ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          1
        "
        :labelReasonsCounter="'Número de pronunciamientos de interés general emitidos por el DCF según el motivo seleccionado'"
        :labelProductCounter="'Número de pronunciamientos de interés general emitidos por el DCF según el producto seleccionado'"
        :products_reasons.sync="mainData.step_1_3._1_3_4_producto_motivo"
        :hideTopInputs="true"
        :soloPreview="soloPreview"
      />

      <BaseTextarea
        v-if="
          mainData.step_1_3
            ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          1
        "
        class="mt-10 col-start-1 col-span-2"
        ref="describe_step_1324_1"
        id="describe_step_1324_1"
        label="Relacione y describa brevemente los pronunciamientos que el DCF, a su juicio, considere de interés general.(Por favor omita nombre y datos identificatorios del consumidor financiero para garantizar la reserva de los mismos. Tampoco realice transcripciones textuales de las respectivas decisiones.)"
        placeholder=""
        maxlength="1000"
        :disabled="soloPreview"
        v-model="
          mainData.step_1_3
            ._1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF_juicio
        "
      />

      <BaseRadio
        v-if="
          mainData.step_1_3
            ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
          1
        "
        ref="conocimiento_entidad_pronunciamientos_interés_general_DCF"
        name="conocimiento_entidad_pronunciamientos_interés_general_DCF"
        label="¿Puso en conocimiento de la entidad los pronunciamientos de interés general que emitió la DCF?"
        class="mt-10 mb-10"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'step_1324_1')"
        :radioOptions="options.oYesOrNot"
        required="true"
        :disabled="soloPreview"
        v-model="
          mainData.step_1_3
            ._1_3_2_4_conocimiento_entidad_pronunciamientos_interes_general_emitio_DCF
        "
      />

      <BaseTextarea
        v-if="
          mainData.step_1_3
            ._1_3_2_4_conocimiento_entidad_pronunciamientos_interes_general_emitio_DCF ==
          1
        "
        class="mt-10 col-start-1 col-span-2"
        ref="describe_step_1324"
        id="describe_step_1324"
        label="En caso afirmativo, por favor indique si tiene conocimiento de alguna actividad desplegada por la entidad al respecto"
        placeholder=""
        maxlength="1000"
        :disabled="soloPreview"
        v-model="
          mainData.step_1_3
            ._1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF
        "
      />
    </Accordion>
  </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import ProductReasons from "../product_reasons_funtional.vue";
import TemplateProductInputs from "../template_product_inputs.vue";
import jOptions from "../../../../domain/entities/justification_options";
import exOptions from "../../../../domain/entities/exclutions_options";
import BtnAddProductReason from "../btn_add_product_reason.vue";
import { isEmpty, isEqual } from "lodash";
import ProductReasonField from "../../../../../annual_report_v2/presentation/components/ProductReasonsField";
import ProductReasonsWithTableField from "../../../../../annual_report_v2/presentation/components/ProductReasonsWithTableField";

const columnsCausal = [
  {
    title: "",
    dataIndex: "labelName",
    scopedSlots: { customRender: "labelName" },
  },
  {
    title: "",
    dataIndex: "count",
    scopedSlots: { customRender: "count" },
  },
];

const columns = [
  {
    slots: { title: "Producto" },
    dataIndex: "producto",
    key: "producto",
    width: 80,
  },
  {
    slots: { title: "n" },
    dataIndex: "n",
    key: "n",
    width: 80,
  },
];

export default {
  components: {
    TemplateProductInputs,
    Accordion,
    ProductReasons,
    BtnAddProductReason,
    ProductReasonField,
    ProductReasonsWithTableField,
  },
  props: {
    soloPreview: {
      type: Boolean,
      default: false,
    },
    mainData: {},
    open: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      columnsCausal: columnsCausal,
      openAccordions: {
        acc_3: true,
        acc_31: false,
        acc_32: false,
        acc_33: false,
        acc_34: false,
        showJustInad1: false,
        showJustInad2: false,
        showJustInad3: false,
        showJustInad4: false,
      },
      showStep: {
        step_131: false,
        step_1321: null,
        step_13211: null,
        step_13211_4: false,
        step_13211_2: false,
        step_13211_1: false,
        step_13211_1_mp: false,
        step_13211_1_j: false,
        step_1322: null,
        step_1323: false,
        step_1323_1: false,
        step_1323_2: false,
        step_1323_3: false,
        step_1323_4: false,
        step_1323_r: false,
        step_1323_t: false,
        step_1323_tn: false,
        step_1324: false,
        step_1324_1: false,
      },
      options: {
        oJustInadmision: [
          {
            id: "1",
            labelName:
              "1) Causal legal de exclusión de competencia considerada por la entidad",
          },
          { id: "2", labelName: "2) Se refiere a otra entidad vigilada" },
          {
            id: "3",
            labelName:
              "3) Solicitud incompleta, no clara, lenguaje soez o agresivo",
          },
          { id: "4", labelName: "4) Otra" },
        ],
        oTipoJustInCausaLegal: [
          {
            id: "1",
            labelName:
              "a. No corresponde al giro ordinario de las operaciones de la entidad",
          },
          {
            id: "2",
            labelName:
              "b. Se refiere al vínculo laboral entre la entidad y sus empleados o contratistas",
          },
          {
            id: "3",
            labelName: "c. Se deriva de condición de accionista de la entidad",
          },
          {
            id: "4",
            labelName:
              "d. Se refiere al reconocimiento de las prestaciones y de las pensiones de invalidez, de vejez y de sobrevivientes",
          },
          {
            id: "5",
            labelName:
              "e. Se encuentra en trámite judicial o arbitral o hayan sido resueltas en estas vías",
          },
          {
            id: "6",
            labelName:
              "f. Corresponde a la decisión sobre la prestación de un servicio o producto",
          },
          {
            id: "7",
            labelName:
              "g. Se refieren a hechos sucedidos con tres (3) años o más de anterioridad",
          },
          {
            id: "8",
            labelName:
              "h. Han sido objeto de decisión previa por parte del Defensor por tener mismos hechos y afectar a las mismas partes",
          },
          { id: "9", labelName: "i. Cuantía supera los cien (100) SMLMV" },
          { id: "10", labelName: "j. Otra" },
        ],
        oYesOrNot: [
          { id: 1, name: "Sí", boolValue: true },
          { id: 2, name: "No", boolValue: false },
        ],
        doOrDoNot: [
          { id: 1, name: "Aplica", boolValue: true },
          { id: 2, name: "No Aplica", boolValue: false },
        ],
        checkOptions: [
          {
            id: 1,
            name: "Si, por reglamento (en todos los casos)",
            disable: false,
          },
          { id: 2, name: "Si, por acuerdo previo y expreso", disable: false },
          { id: 3, name: "Según criterios", disable: false },
          { id: 4, name: "No", disable: false },
        ],
        justificationOptions: [],
        exclutionOptions: [],
      },
      equalTotalEmitter: false,
      totalmente: null,
      parcialmente: null,
    };
  },
  watch: {
    "mainData.step_1_3._1_3_2_3_pronunciamientos_DCF_obligatorios_entidad": {
      handler(newVal, oldVal) {
        if (newVal && Array.isArray(newVal)) {
          this.options.checkOptions = this.options.checkOptions.map((value) => {
            if (newVal.includes(1)) {
              value.checked = value.id == 1;
              value.disable = !value.checked;
            } else {
              value.checked = newVal.includes(value.id);
              value.disable = false;
            }
            return value;
          });
          this.$forceUpdate();
        }
      },
      immediate: true,
    },
    "mainData.step_1_3._1_3_2_3_total_decisiones_emitidas_a_favor_consumidor_financiero":
      {
        handler(newVal, oldVal) {
          const totalAndPartial = `${
            Number(
              this.mainData.step_1_2
                ._1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero
            ) +
            Number(
              this.mainData.step_1_2
                ._1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero
            )
          }`;

          if (newVal && newVal != totalAndPartial) {
            this.equalTotalEmitter = true;
          } else {
            this.equalTotalEmitter = false;
          }
        },
        immediate: true,
      },
  },
  created() {
    this.options.justificationOptions = jOptions;
    this.options.exclutionOptions = exOptions;
  },
  methods: {
    valueCausal(id) {
      const item =
        this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail?.find(
          (element) => element.id === id
        );

      if (item) {
        return item.count;
      } else {
        console.error("No se encontró un elemento con el id:", id);
        return null;
      }
    },
    updateDetail(key, value) {
      const detailIndex =
        this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail?.findIndex(
          (detail) => detail.id === key
        );
      if (detailIndex >= 0) {
        this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail[
          detailIndex
        ].count = value;
      } else {
        this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail?.push(
          { id: key, count: value }
        );
      }
      this.mainData = {
        ...this.mainData,
        ...this.mainData,
      };

      this.$forceUpdate();
    },
    isRowSelected(key) {
      return this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion?.includes(
        key
      );
    },
    causalLegalIncludeOtra() {
      const item =
        this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail?.findIndex(
          (detail) => detail.id == "10"
        );
      return item >= 0;
    },
    isJustInadActive(value) {
      if (
        !isEmpty(
          this.mainData.step_1_3
            ._1_3_2_1_justificacion_no_competencia_segun_entidad_ids
        )
      ) {
        return this.mainData.step_1_3._1_3_2_1_justificacion_no_competencia_segun_entidad_ids.includes(
          value
        );
      } else {
        return false;
      }
    },
    isChecks(value) {
      if (
        !isEmpty(
          this.mainData.step_1_3
            ._1_3_2_3_pronunciamientos_DCF_obligatorios_entidad
        )
      ) {
        return this.mainData.step_1_3._1_3_2_3_pronunciamientos_DCF_obligatorios_entidad.includes(
          value
        );
      } else {
        return false;
      }
    },
    onChangeSelectTipoJustInCausaLegal(values) {
      if (
        !this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion.includes(
          values
        )
      ) {
        this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion.push(
          values
        );
      }
      this.$forceUpdate();
    },
    onChangeSelectJustInadmision(values) {
      if (
        !isEqual(
          values,
          this.mainData.step_1_3
            ._1_3_2_1_justificacion_no_competencia_segun_entidad_ids
        )
      ) {
        this.mainData.step_1_3._1_3_2_1_justificacion_no_competencia_segun_entidad_ids =
          values;
      }
      this.$forceUpdate();
    },
    setFiles(files) {
      this.mainData.step_1_3._1_3_2_4_anexo = files;
      this.$forceUpdate();
    },
    add(key) {
      this.$refs[key].addProductReasons();
    },
    getProductReasons(value, key) {
      if (value.clave === key) {
        delete value.clave;
        this.mainData.step_1_3[key] = [...value];
      }
    },
    disbledOthers(active) {
      switch (active) {
        case 1:
          this.showStep.step_1323_1 = true;
          this.showStep.step_1323_2 = false;
          this.showStep.step_1323_3 = false;
          this.showStep.step_1323_4 = false;
          break;
        case 2:
          this.showStep.step_1323_1 = false;
          this.showStep.step_1323_2 = true;
          this.showStep.step_1323_3 = false;
          this.showStep.step_1323_4 = false;
          break;
        case 3:
          this.showStep.step_1323_1 = false;
          this.showStep.step_1323_2 = false;
          this.showStep.step_1323_3 = true;
          this.showStep.step_1323_4 = false;
          break;
        case 0:
          this.showStep.step_1323_1 = false;
          this.showStep.step_1323_2 = false;
          this.showStep.step_1323_3 = false;
          this.showStep.step_1323_4 = false;
          break;
      }
    },
    onChangeRadio(value, key) {
      if (key === "step_1323_t") {
        if (!value.boolValue) {
          this.showStep.step_1323_tn = true;
        }
      }
      if (key === "step_1321") {
        this.showStep.step_13211_4 = false;
        this.showStep.step_13211 = null;
        this.showStep.step_13211_2 = false;
        this.showStep.step_13211_1 = false;
        this.showStep.step_13211_1_mp = false;
        this.showStep.step_13211_1_j = false;
      }

      this.showStep[key] = value.boolValue;
      this.$forceUpdate();
    },
    onChangeCheck(values) {
      if (!isEmpty(values)) {
        if (!values.includes(1)) {
          this.$set(
            this.mainData.step_1_3,
            "_1_3_2_3_pronunciamientos_DCF_obligatorios_entidad",
            values
          );
        }

        if (values.includes(1)) {
          this.$set(
            this.mainData.step_1_3,
            "_1_3_2_3_pronunciamientos_DCF_obligatorios_entidad",
            [1]
          );
        }
      } else {
        this.$set(
          this.mainData.step_1_3,
          "_1_3_2_3_pronunciamientos_DCF_obligatorios_entidad",
          []
        );
      }
    },
    onChangeSelect(value, key) {
      if (Array.isArray(key)) {
        if (value.id === 1) {
          this.showStep.step_13211_1_mp = false;
          this.showStep.step_13211_1 = value.boolValue;
          this.showStep.step_13211_4 = false;
          this.showStep.step_13211_2 = false;
          this.showStep.step_13211_1_j = false;
          this.showStep.step_13211_2 = false;
          return;
        }
        if (value.id === 4) {
          this.showStep.step_13211_1_mp = false;
          this.showStep.step_13211_4 = value.boolValue;
          this.showStep.step_13211_2 = value.boolValue;
          this.showStep.step_13211_1_j = false;
          this.showStep.step_13211_1 = false;
          return;
        }
        if (value.id === 3) {
          this.showStep.step_13211_1_mp = false;
          this.showStep.step_13211_4 = false;
          this.showStep.step_13211_2 = value.boolValue;
          this.showStep.step_13211_1_j = false;
          this.showStep.step_13211_1 = false;
          return;
        }
        if (value.id === 2) {
          this.showStep.step_13211_1_mp = false;
          this.showStep.step_13211_4 = false;
          this.showStep.step_13211_2 = value.boolValue;
          this.showStep.step_13211_1_j = false;
          this.showStep.step_13211_1 = false;
          return;
        }
      } else {
        this.showStep[key] = value.boolValue;
      }
    },
  },
  computed: {
    totalCausales_1_3_2_1() {
      return this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail?.reduce(
        (sum, item) => {
          return sum + Number(item.count);
        },
        0
      );
    },
    rowSelection() {
      const selectedRowKeys = this.options.oTipoJustInCausaLegal
        .map((objeto, index) =>
          this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion?.includes(
            objeto.id
          )
            ? index
            : null
        )
        .filter((index) => index !== null);

      return {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion = [];
          selectedRows.forEach((element) => {
            this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion?.push(
              element.id
            );
          });

          const updatedDetail = [];

          this.options.oTipoJustInCausaLegal.forEach((element) => {
            if (
              this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion?.includes(
                element.id
              )
            ) {
              const justDetail =
                this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail?.find(
                  (detail) => detail.id === element.id
                );

              updatedDetail.push({
                id: element.id,
                labelName: element.labelName,
                count: justDetail?.count ?? null,
              });
            }
          });

          this.mainData.step_1_3._1_3_2_1_causal_legal_tipo_justificacion_detail =
            updatedDetail;

          this.mainData = {
            ...this.mainData,
            ...this.mainData,
          };
        },
        getCheckboxProps: (record) => ({
          props: {
            disable: false,
            name: record.name,
            defaultChecked: record.isChecked ?? false,
          },
        }),
      };
    },
  },
};
</script>