<template>
    <div class="w-full">
        <div v-for="(item, index) in product_reasons" :key="index">

            <ProducReasons :ref="clave" :titleM="titleM" :titleP="titleP" :isDelete="index > 0 ? true : false"
                :key="index" 
                @inputMotivo="(value) => {
            product_reasons[index].contador_motivo = parseInt(value)
            changeProductReason()
        }" @inputProducto="(value) => {
            product_reasons[index].contador_producto = parseInt(value)
            changeProductReason()
        }" @selectProducto="(value) => {
            product_reasons[index].producto = value
            changeProductReason()
            getCounterProduct(value)
        }" @selectMotivo="(value) => {
            product_reasons[index].motivo = value
            changeProductReason()
            getCounterReasons(value)
        }" @inputDescripcionMotivo="(value) => {
            product_reasons[index].descripcion_motivo = value
            changeProductReason()
        }" @inputDescripcionProducto="(value) => {
            product_reasons[index].descripcion_producto = value
            changeProductReason()
        }" @delete="removeProductReasons(index)" />
        </div>

    </div>
</template>

<script>

import ProducReasons from './template_product_reasons.vue';
import { isEmpty } from 'lodash'
import AnnualReportServices from "../../../data/services/annual_report_services";

const data = [{
    producto: "",
    contador_producto: null,
    descripcion_producto: "",
    descripcion_motivo: "",
    motivo: [],
    contador_motivo: null
}]

const url = '?insta_recepcion_id=3'

export default {
    components: { ProducReasons },
    data() {
        return {
            value_r: 0,
            valueL: 0,
            product_reasons: [],
        }
    },
    props: ["titleM", "titleP", "clave", "initialProductReasons"],
    created() {
        this.product_reasons = this.initialProductReasons ?? data;
    },
    methods: {
        async getCounterReasons(reasons) {
            if (!isEmpty(reasons)) {
                let data = await this.getCounters(null, reasons);

                this.$notification.close('success_motivo');

                this.$notification.success({
                    key: 'success_motivo',
                    message: "Codigo de motivo: " + reasons,
                    description: "Total motivo: " + data?.reasons_count?.toString(),
                });
            }
        },
        async getCounterProduct(product) {

            let data = await this.getCounters(product, null);

            this.$notification.close('success_producto');

            this.$notification.success({
                key: 'success_producto',
                message: "Codigo de producto: " + product,
                description: "Total producto: " + data?.products_count?.toString(),
            });
        },
        async getCounters(product, reasons) {
            let json = {
                "entity_code": 3,
                "products": product != null ? [product] : [],
                "reasons": reasons != null ? reasons : [],
            }

            let data = await AnnualReportServices.getCounters(json, url);

            if (!data.isSuccess) {
                this.$notification.close("request")
                this.$notification.warning({
                    key: 'request',
                    message: data.message
                })
            }
            return data.data;

        },
        addProductReasons() {
            this.product_reasons.push({
                producto: "",
                contador_producto: null,
                motivo: [],
                contador_motivo: null, descripcion_producto: "",
                descripcion_motivo: "",
            },)
        },
        removeProductReasons(index) {
            this.product_reasons.splice(index, 1);
            this.changeProductReason()
        },
        changeProductReason() {

            Object.assign(this.product_reasons, { "clave": this.clave });

            this.$emit('product_reasons', this.product_reasons);
        }
    },
};
</script>