export default [
  {
    id: 1,
    name: "1. Causal legal de exclusión de competencia",
    boolValue: true,
  },
  {
    id: 2,
    name: "2. Se refiere a otra entidad vigilada",
    boolValue: true,
  },
  {
    id: 3,
    name: "3. Solicitud incompleta, no clara, lenguaje soez o agresivo",
    boolValue: true,
  },
  {
    id: 4,
    name: "4. Otra",
    boolValue: true,
  },
];
