<template>
    <div class="w-full">
        <div class="grid grid-cols-2 gap-x-8 mb-10">
            <BaseInput :ref="refL" :type="'number'" :numeric="true" :label="titleL" :required="true"
                @input="onChangeDataL" v-model="valueL" />

            <BaseInput v-if="!disableIR" :ref="refR" :type="'number'" :numeric="true" :label="titleR" :disabled="true" :value="value_r" />
        </div>

        <productReasonsFunc :ref="clave" :titleM="titleM" :titleP="titleP" :urlGetCounter="urlGetCounter" :initialProductReasons="product_reasons"
            @product_reasons="changeProductReason" :clave="clave" />

        <BtnAddProductReason @click="addProductReasons" />
    </div>
</template>

<script>
import productReasonsFunc from './product_reasons_funtional.vue';
import { debounce } from 'lodash';
import AnnualReportServices from "../../../data/services/annual_report_services";
import BtnAddProductReason from './btn_add_product_reason.vue';

export default {
    components: { productReasonsFunc, BtnAddProductReason },
    data() {
        return {
            value_r: 0,
            disableIR: false,
            debouncedOnChangeDataL: null,
            debouncedValidateCounterProduct: null,
        }
    },
    props: ["titleM", "titleP", "titleL", "titleR", "refL", "refR", "urlGetCounter", "clave", "disableInputR", "valueL", "product_reasons"],
    created() {
        this.disableIR = this.disableInputR ?? false;
        this.debouncedOnChangeDataL = debounce(async (value) => {
            this.valueL = parseInt(value);
            this.$emit('onChangeL', this.valueL);
            if (!this.disableIR) {
                this.counterDFC();
            }
        }, 1000);
        this.debouncedValidateCounterProduct = debounce(async () => {
            let counter = 0;

            this.product_reasons.map((item) => {
                counter += item.contador_producto;
            });

            if (this.valueL === counter && counter !== 0) {
                this.$notification.close("success_pm");
                this.$notification.close("fail_pm");

                this.$notification.success({
                    key: 'success_pm',
                    message: "Coincide el número registrado por el DCF con los productos/motivos gestionados"
                });
            } else if (this.valueL !== counter && counter !== 0) {
                this.$notification.close("success_pm");
                this.$notification.close("fail_pm");

                this.$notification.warning({
                    key: 'fail_pm',
                    message: "No coincide el número registrado por el DCF con los productos/motivos gestionados"
                });
            }
        }, 1000);
    },
    methods: {
        async getCounters() {
            let json = {
                "entity_code": 3,
                "products": [],
                "reasons": [],
            }

            let data = await AnnualReportServices.getCounters(json, this.urlGetCounter);

            if (!data.isSuccess) {
                this.$notification.close("request");
                this.$notification.warning({
                    key: 'request',
                    message: data.message
                });
            }
            return data.data;
        },
        async counterDFC() {
            let data = await this.getCounters();

            this.value_r = data.complaint_count;

            if (this.valueL !== this.value_r) {
                this.$notification.close("counter");
                this.$notification.warning({
                    key: 'counter',
                    message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
                });
            }
        },
        onChangeDataL(value) {
            this.debouncedOnChangeDataL(value);
        },
        validateCounterProduct() {
            this.debouncedValidateCounterProduct();
        },
        addProductReasons() {
            this.$refs[this.clave].addProductReasons();
        },
        changeProductReason(value) {
            this.product_reasons = value;
            this.validateCounterProduct();
            this.$emit('data_product_reasons', value);
        }
    }
};
</script>
