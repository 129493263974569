var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},_vm._l((_vm.product_reasons),function(item,index){return _c('div',{key:index},[_c('ProducReasons',{key:index,ref:_vm.clave,refInFor:true,attrs:{"titleM":_vm.titleM,"titleP":_vm.titleP,"isDelete":index > 0 ? true : false},on:{"inputMotivo":(value) => {
        _vm.product_reasons[index].contador_motivo = parseInt(value)
        _vm.changeProductReason()
    },"inputProducto":(value) => {
        _vm.product_reasons[index].contador_producto = parseInt(value)
        _vm.changeProductReason()
    },"selectProducto":(value) => {
        _vm.product_reasons[index].producto = value
        _vm.changeProductReason()
        _vm.getCounterProduct(value)
    },"selectMotivo":(value) => {
        _vm.product_reasons[index].motivo = value
        _vm.changeProductReason()
        _vm.getCounterReasons(value)
    },"inputDescripcionMotivo":(value) => {
        _vm.product_reasons[index].descripcion_motivo = value
        _vm.changeProductReason()
    },"inputDescripcionProducto":(value) => {
        _vm.product_reasons[index].descripcion_producto = value
        _vm.changeProductReason()
    },"delete":function($event){return _vm.removeProductReasons(index)}}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }