<template>
  <div class="w-full mt-5 flex flex-col">
    <div class="flex space-around">
      <p
        class="w-full mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80"
        :class="{ 'text-red-500': invalid }"
        v-if="!!label"
      >
        <b>{{ label }}</b
        ><span v-if="required" class="text-red-500">*</span>
      </p>
      <label class="flex items-center mx-2 text-xs md:text-sm" v-if="cleanable">
        <button ref="checkAll" class="clear mr-2 text-xs" @click="clean">
          Limpiar
        </button>
      </label>
    </div>
    <div class="grid grid-cols-2 items-center">
      <label
        class="flex items-center mx-2 text-xs md:text-sm"
        :class="{ 'text-red-500': invalid, 'text-gray-400': disabled }"
        v-for="(option, index) in radioOptions"
        :key="option.id"
      >
        <!-- <pre>{{ option }}</pre> -->
        <input
          :ref="name"
          class="mr-2"
          type="radio"
          :checked="value == option.id ? true : false"
          :id="id + index"
          :class="{ 'text-gray-400': option.conditional ? true : false }"
          :name="name"
          :value="value"
          :disabled="option.conditional ? true : false"
          @change="onInputRadio(option)"
        />
        {{ option.name }}
      </label>
    </div>
    <p v-if="invalid" class="mt-2 text-xs text-left text-red-500">
      Este campo es obligatorio
    </p>
  </div>
</template>

<script>
export default {
  props: [
    "name",
    "id",
    "value",
    "label",
    "radioOptions",
    "required",
    "disabled",
    "cleanable",
  ],
  data() {
    return {
      invalid: false,
    };
  },
  mounted() {
    if (this.value != "") {
      this.invalid = false;
    } else if (this.value != "" && this.required) {
      this.invalid = true;
    }
  },
  methods: {
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid;
      }
    },
    clean() {
      this.$refs[this.name].forEach((input) => (input.checked = false));
      this.$emit("cleaned", this.name);
    },
    onInputRadio(value) {
      this.$emit("input", value.id);
      this.$emit("onChangeValue", value.id);
      this.$emit("onChangeValueGetValue", value, this.name);
    },
  },
  watch: {
    value: function () {
      if (this.value != "") {
        this.invalid = false;
      } else if (this.value != "" && this.required) {
        this.invalid = true;
      }
    },
  },
};
</script>

<style>
.clear {
  font-size: 0.7px;
  border: solid 1px gray;
  padding: 1px 4px;
  border-radius: 1em;
}
</style>