<template>
  <div class="w-100 mb-10">

    <Accordion :open="true" :disable="false" key="produc_reasons" class="w-100" header="Producto y motivo"
      :isDelete="isDelete" @deletecomponent="deleteData">

      <div class="w-full
            grid
            grid grid-cols-2
            gap-y-6
            gap-x-10
            justify-end
            items-end">

        <BaseSelect label="Producto" @change="selectProducto" :selectOptions="optionsProducts" v-model="product" />
        <BaseInput :type="'number'" :numeric="true" :label="titleP" @input="inputProducto" v-model="counter_product" />

        <BaseTextarea v-if="showDescriptionProduct" class="col-start-1 col-span-2" label="Descripción" placeholder=""
          maxlength="100" @input="inputDescripcionProducto" v-model="description_product" />

        <BaseMultiSelect title="Motivo" label="name" @notify-objects="selectMotivo" :data="optionsReasons"
          v-model="reasons" />
        <BaseInput :type="'number'" :numeric="true" :label="titleM" @input="inputMotivo" v-model="counter_reasons" />

        <BaseTextarea v-if="showDescriptionReasons" class="col-start-1 col-span-2" label="Descripción" placeholder=""
          maxlength="100" @input="inputDescripcionMotivo" v-model="description_reasons" />
      </div>

    </Accordion>


  </div>
</template>


<script>
import Accordion from "../../../../../components/Dashboard/FCO/Accordion.vue";

export default {
  components: {
    Accordion,
  },
  props: ["titleM", "titleP", "isDelete"],
  data() {
    return {
      product: "",
      reasons: "",
      showDescriptionProduct: false,
      showDescriptionReasons: false,
      counter_product: null,
      description_product: "",
      description_reasons: "",
      counter_reasons: null,
      optionsReasons: [],
      optionsProducts: [],
    }
  },
  created() {
    this.initDataProductReasons(3)
  },
  methods: {
    inputDescripcionMotivo(value) {
      this.$emit('inputDescripcionMotivo', value)
    },
    inputDescripcionProducto(value) {
      this.$emit('inputDescripcionProducto', value)
    },
    inputMotivo(value) {
      this.$emit('inputMotivo', value)
    },
    inputProducto(value) {
      this.$emit('inputProducto', value)
    },
    selectProducto(value) {
      if (value === '0') {
        this.showDescriptionProduct = true;
      } else {
        this.showDescriptionProduct = false;
      }
      this.$emit('selectProducto', value)
    },
    selectMotivo(value) {
      let idsReasons = value.map((val) => val.id.toString())

      if (idsReasons.includes('99')) {
        this.showDescriptionReasons = true;
      } else {
        this.showDescriptionReasons = false;
      }
      this.$emit('selectMotivo', idsReasons)
    },
    deleteData() {
      this.$emit('delete');
    },
    getOptions(key) {
      switch (key) {
        case "optionsReasons":
          return this.optionsReasons
        case "optionsProducts":
          return this.optionsProducts

      }
    },
    async initDataProductReasons(value) {
      if (value) {
        let optionsReasons = await this.$store.dispatch(
          "form/loadReasonsOptions",
          { company: value }
        );
        let optionsProducts = await this.$store.dispatch(
          "form/loadProductsOptions",
          { company: value }
        );
        const otherOption = {
          "id": 0,
          "name": "OTROS"
        }

        optionsProducts.push(otherOption);
        this.optionsReasons = optionsReasons;
        this.optionsProducts = optionsProducts;
      }
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    }
  }
};
</script>
