<template>
    <div style="overflow-y: unset;">
        
        
        <!--  **************************************  START STEP 4  *********************************************  -->
        <Accordion :open="openAccordions.acc_4" :disable="false" key="astep1" class="px-0"
            header="4. Información adicional">
            
            <div class="w-full grid grid grid-cols-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4">

                <!--  **************************************  START STEP 4.1  *********************************************  -->
                <Accordion :open="openAccordions.acc_41" :disable="false" key="astep1" class="px-0 col-start-1 col-span-4"
                    header="4.1 Conclusiones de las reuniones que se realizaron durante el período (con la revisoría fiscal, las áreas de control interno, servicio al consumidor financiero, u otras oficinas, dependencias o funcionarios de la entidad) para evaluar los servicios y los pronunciamientos del DCF sobre casos frecuentes, cuantiosos o importantes a juicio del DCF">

                    <div class="w-full grid grid grid-cols-5 gap-y-2 justify-end items-end pt-0 mt-0 mb-4">
                        <a-table class="col-start-1 col-span-5" bordered 
                            :data-source="mainData.paso_4_1.encuentros" 
                            :columns="columns" 
                            :pagination="paginationConfig" 
                            @change="handleTableChange"
                            style="overflow-y: scroll">
                            <template slot="tematica" slot-scope="text, record">
                                <editable_cell :disabled="soloPreview" :text="text" @change="onCellChange(record.key, 'tematica', $event)" :maxLength="100" placeholder="Escribe la temática..."></editable_cell>
                            </template>
                            <template slot="participantes" slot-scope="text, record">
                                <editable_cell :disabled="soloPreview" :text="text" @change="onCellChange(record.key, 'participantes', $event)" :maxLength="100" placeholder="Ingresa los participantes..."></editable_cell>
                            </template>
                            <template slot="conclusiones" slot-scope="text, record">
                                <editable_cell :disabled="soloPreview" :text="text" @change="onCellChange(record.key, 'conclusiones', $event)" :maxLength="1000" placeholder="Ingresa las conclusiones..."></editable_cell>
                            </template>
                        </a-table>

                        <a-button v-if="!soloPreview" class="col-end-4 editable-add-btn" @click="handleAdd">
                            Agregar
                        </a-button>
                    </div>

                </Accordion>

                <!--  **************************************  START STEP 4.2  *********************************************  -->
                <Accordion ref="s_4_2"  :open="openAccordions.acc_42" :disable="false" key="astep2" class="px-0 col-start-1 col-span-4"
                    header="4.2 Requerimientos humanos y técnicos para el funcionamiento del DCF">
                    
                    <div class="w-full grid grid grid-cols-4 gap-6 justify-end items-end">
                        
                        <BaseRadio ref="opt_dcf_hizo_solicitudes" name="opt_dcf_hizo_solicitudes" :disabled="soloPreview"
                            label="¿La DCF hizo solicitudes de requerimientos humanos o técnicos a la EV?" @onChangeValueGetValue="onChangeRadio"
                            :radioOptions="options.oYesOrNot" required="true" class="col-span-3" v-model="mainData.paso_4_2.dcf_hizo_solicitudes"/>         

                    </div>
                    <div v-if="mainData.paso_4_2.dcf_hizo_solicitudes" class="w-full grid grid grid-cols-4 gap-6 justify-end items-end col-start-1 col-span-4 mt-8">
                        <BaseTextarea
                            v-model="mainData.paso_4_2.descripcion"
                            class="col-start-1 col-span-4"
                            ref="describe"
                            id="describe"
                            label="Describa la solicitud que hizo a la entidad"
                            placeholder="Mensaje"
                            maxlength="400"
                            :disabled="soloPreview"
                        />
                        <BaseDate id="date-input" v-model="mainData.paso_4_2.fecha_requerimiento" @input="onInputFechaRequerimiento"
                            label="Fecha del requerimiento efectuado por la DCF" :disabled="soloPreview"
                            required="true" type="text" placeholder="DD/MM/AAAA" class="col-start-1 col-span-2"/>
                        <BaseRadio ref="opt_entidad_suministro_solicitado" name="opt_entidad_suministro_solicitado" :disabled="soloPreview"
                            label="¿La entidad suministró lo solicitado por la DCF?" @onChangeValueGetValue="onChangeRadio"
                            :radioOptions="options.oYesOrNot" required="true" class="col-span-3" 
                            v-model="mainData.paso_4_2.entidad_suministro_solicitado"/> 
                        
                        <BaseDate v-if="mainData.paso_4_2.entidad_suministro_solicitado" id="date-input"
                            v-model="mainData.paso_4_2.fecha_atencion" :disabled="soloPreview"
                            label="¿Cuándo atendió la EV el requerimiento de la DCF?" 
                            :disabledDate="disabledDate"
                            required="true" type="text" placeholder="DD/MM/AAAA" class="col-start-1 col-span-2"/>
                        <BaseTextarea v-if="mainData.paso_4_2.entidad_suministro_solicitado" 
                            v-model="mainData.paso_4_2.descripcion_ev" 
                            class="col-start-1 col-span-4"
                            ref="describe"
                            id="describe"
                            label="¿Cómo atendió la EV el requerimiento de la DCF?"
                            placeholder="Mensaje"
                            maxlength="400"
                            :disabled="soloPreview"
                        />
                        <BaseTextarea v-if="mainData.paso_4_2.entidad_suministro_solicitado == false"
                            v-model="mainData.paso_4_2.justificacion_ev" 
                            class="col-start-1 col-span-4"
                            ref="describe"
                            id="describe"
                            label="¿Cuál es la justificación de la entidad frente a la negativa?"
                            placeholder="Mensaje"
                            maxlength="400"
                            :disabled="soloPreview"
                        />
                    </div>
                    <div class="w-full grid grid grid-cols-4 gap-6 justify-end items-end col-start-1 col-span-4 mt-8">
                        
                    </div>

                </Accordion>

                <!--  **************************************  START STEP 4.3  *********************************************  -->
                <Accordion :open="openAccordions.acc_43" :disable="false" key="astep3" class="px-0 col-start-1 col-span-4"
                    header="4.3 Otra información de interés para la SFC">
                    
                    <BaseTextarea
                        v-model="mainData.paso_4_3.informacion_interes"
                        class="col-start-1 col-span-2"
                        ref="describe"
                        id="describe"
                        label="Describa otros aspectos que como DCF estime pertinente dar a conocer a la SFC con referencia al desenvolvimiento o desarrollo de sus funciones con la EV para la cual presta sus servicios"
                        placeholder="Mensaje"
                        maxlength="1000"
                        :disabled="soloPreview"
                    />                 
                    
                </Accordion>
            </div>

        </Accordion>
    </div>
</template>


<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import editable_cell from "@/feature/annual_report/presentation/views/components/editable_cell";
import moment from "moment";


export default {
    components: {
        Accordion,
        editable_cell
    },
    props: {
        initialData: {
            type: Object,
            default: null,
        },
        soloPreview: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isFisrtTime: true,
            hasChanged: false,
            value: '',
            paginationConfig: {
                current: 1,
                pageSize: 10,
            },
            editable: false,
            targetOffset: undefined,
            disabledDate: (current) => {
                const fechaRequerimientoMoment = moment(this.mainData.paso_4_2.fecha_requerimiento);
                fechaRequerimientoMoment.subtract(1, 'days');
                return current?.isBefore(fechaRequerimientoMoment.endOf('day'));
            },
            columns: [
                {
                    title: 'Temática',
                    dataIndex: 'tematica',
                    width: 200,
                    maxLength:150,
                    scopedSlots: { customRender: 'tematica' },
                },
                {
                    title: 'Participantes',
                    dataIndex: 'participantes',
                    width: 250, 
                    maxLength:100,
                    scopedSlots: { customRender: 'participantes' },
                },
                {
                    title: 'Conclusiones',
                    dataIndex: 'conclusiones',
                    maxLength: 1000,
                    width: 500, 
                    scopedSlots: { customRender: 'conclusiones' },
                }
            ],
            mainData: {
                paso_4_1: {
                    encuentros:[],  // { "tematica": "100", "participantes": "100", "conclusiones": "1000" }
                    cantidad_encuentros: 0
                },
                paso_4_2: {
                    dcf_hizo_solicitudes: null, // boolean
                    descripcion: "",  // str:400
                    fecha_requerimiento: null,  // date
                    entidad_suministro_solicitado: null, // boolean
                    fecha_atencion: null,  // date
                    descripcion_ev: "",  // str:400
                    justificacion_ev: "",  // str:400
                },
                paso_4_3: {
                    informacion_interes: ""  // str:1000
                }
            },
            openAccordions:{
                acc_4: true,
                acc_41: false,
                acc_42: false,
                acc_43: false,
            },
            options: {
                oYesOrNot: [
                    { id: true, name: "Sí" },
                    { id: false, name: "No" },
                ],
            },
        }
    },
    watch: {
        initialData: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if(newVal != null){
                    this.mainData = newVal;
                    if(!this.isFisrtTime && !this.hasChanged){
                        this.hasChanged = true;
                        console.log('mainData 4 hasChanged:', this.hasChanged)
                    }
                    this.isFisrtTime = false
                }
            }
        }
    },
    created() {
        if(this.initialData == null){
            this.hasChanged = true;
        }
        this.mainData = this.initialData ?? this.mainData;
    },
    methods: {
        notifyChanges(){
            let error = false;
            // Se implementa lógica antes de guardar la info, en caso de ser necesario
            return {error: error, data: this.mainData};
        },
        onChangeRadio(value, radioName){
            console.log('onChangeRadio', value, radioName)
            this.openJustification = value.id == 2;
        },
        onInputFechaRequerimiento(){
            this.mainData.paso_4_2.fecha_atencion = null;
        },
        onCellChange(key, dataIndex, value) {
            const dataSource = [...this.mainData.paso_4_1.encuentros];
            const target = dataSource.find(item => item.key === key);
            if (target) {
                target[dataIndex] = value;
                this.dataSource = dataSource;
            }
        },
        handleTableChange(pagination, filters, sorter) {
            this.paginationConfig.current = pagination.current;
        },
        handleAdd() {
            const newData = {
                key: this.mainData.paso_4_1.cantidad_encuentros,
                tematica: null,
                participantes: null,
                conclusiones: null,
            };
            this.mainData.paso_4_1.encuentros = [...this.mainData.paso_4_1.encuentros, newData];
            this.mainData.paso_4_1.cantidad_encuentros = this.mainData.paso_4_1.cantidad_encuentros + 1;
            this.paginationConfig.current = Math.floor((this.mainData.paso_4_1.cantidad_encuentros + 10) / 10)
            console.log(this.paginationConfig.current, 'this.paginationConfig.current')
        }
    }
};
</script>