<template>
  <div
    class="w-full grid grid xl:grid-cols-4 sm:grid-cols-2 gap-8 justify-start items-start pt-0 mt-0 mb-4"
  >
    <DragDrop
      v-if="!hideDragDrop"
      id="conciliationFiles"
      @files-handler="setFiles"
      :required="required"
      class="col-start-1 col-span-2"
      :fileList="filesObjs"
      :label="dragDropTitle"
      :extensions="extensions"
      :accept="accept"
      buttonLabel="SELECCIONE SUS ARCHIVOS"
    />
    <p v-if="feedback" class="text-sm text-center text-red-500">
      {{ feedback }}
    </p>

    <div
      class="col-span-2 w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600"
    >
      <div
        class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer p-3"
        @click="toggleAccordion"
        style="border-bottom: gray solid 1px"
      >
        <p class="text-dark text-left">
          <b>{{ title_accordion }}</b>
        </p>
        <span
          class="h-5 w-5 m-2 flex justify-center items-center transition"
          :class="{ 'transform rotate-180': accordionOpen }"
        >
          <img src="@/assets/icons/arrow-down.svg" alt="" />
        </span>
      </div>
      <div v-if="accordionOpen" class="py-3 px-6 pb-6">
        <div
          v-if="localFilesList && localFilesList.length > 0"
          class="flex flex-col gap-2"
        >
          <div class="w-full grid grid-cols-1 gap-2">
            <BaseDownloadAttachment
              class="col-span-1"
              v-for="(option, index) in localFilesList"
              label="Anexo"
              :value="option"   
              :can_be_downloaded="option.can_be_downloaded" 
              :file_name="option.file_name"
              :key="index"
              :can_delete_file="enable_delete_file && !hideDragDrop"
              @attachment-2-delete="delete_file"
            />
          </div>
        </div>
        <div v-else class="text-gray-400 mt-2">No hay archivos adjuntos</div>
      </div>
    </div>
  </div>
</template>


<script>
import checkFileSize from "@/utils/checkFileSize";


export default {
  props: {
    title_accordion: {
      type: String,
      default: "Anexos",
    },
    enable_delete_file: {
      type: Boolean,
      default: false,
    },
    filesList: {
      type: Array,
      default: () => [],
    },
    dragDropTitle: {
      type: String,
      default: "Anexar",
    },
    hideDragDrop: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    maxSizePerFile: {
      type: Number,
      default: 1,
    },
    maxFilesSize: {
      type: Number,
      default: 5,
    },
    extensions: {
      type: Array,
      default: () => [
        ".pdf",
        ".jpg",
        ".jpeg",
        ".png",
        ".mp4",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".bmp",
        ".mp3",
        ".msg",
      ],
    },
    accept: {
      type: String,
      default:
        ".pdf,.jpg,.jpeg,.png,.mp4,.doc,.docx,.xls,.xlsx,.bmp,.mp3,.msg,application/vnd.ms-outlook, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv",
    },
  },
  data() {
    return {
      accordionOpen: false,
      loading: false,
      feedback: "",
      filesObjs: [],
      localFilesList: [],
      currentSize: 0,
    };
  },
  
  created() {
    this.localFilesList = this.filesList ?? this.localFilesList;
    let currentSize = 0;
    if (this.localFilesList.length > 0) {
      this.localFilesList.forEach((item) => {
        currentSize += item.size;
      });
      currentSize = currentSize / (1024 * 1024);
    }
    this.currentSize = currentSize;
  },
  methods: {
    async delete_file(index, file){
      console.log(index, file)
      
      this.localFilesList.splice(index, 1);
      console.log('localFilesList in delete', this.localFilesList)
      const filteredList = this.localFilesList.filter(item => item.can_be_downloaded != false);
      console.log('filteredList in delete', filteredList)

      await this.$emit("file-deleted", file, filteredList);
    },  
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
    checkSizePerFile(fileSize) {
      const size = fileSize / (1024 * 1024);
      return size <= this.maxSizePerFile;
    },
    receiveUpdateSignal(filesList){
      console.log('in receiveUpdateSignal:', filesList)
      this.localFilesList = [...filesList];
      this.filesObjs = [];
    },
    setFiles(files) {
      this.feedback = "";
      console.log(this.filesList);
      console.log(this.localFilesList);
      const countFiles = files.length + this.localFilesList.length;

      if (countFiles > this.maxFiles) {
        this.feedback =
          "Con el archivo que intenta cargar, se supera la cantidad máxima permitida de los anexos.";
        return;
      }
      files.forEach((item) => {
        if (!this.checkSizePerFile(item.size)) {
          this.feedback =
            "Con el archivo que intenta cargar, se supera el peso máximo permitido por anexo. Valide la información.";
          return;
        }
      });
      if (this.feedback != "") {
        return;
      }

      if (checkFileSize(files, this.maxFilesSize - this.currentSize)) {
        this.feedback =
          "Con el archivo que intenta cargar, se supera el peso máximo permitido de los anexos. Valide la información.";
      } else {
        this.localFilesList = [...this.filesList];
        files.forEach((file) => {
          this.localFilesList.push({
            file_name: file.name,
            can_be_downloaded: false,
            size: file.size,
          });
        });
        console.log(this.localFilesList);
        this.filesObjs = files;
        this.$emit("files-changed", files);
      }
    },
  },
};
</script>