<template>
    <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-textarea :value="value" @change="handleChange" :maxLength="maxLength" :placeholder="placeholder" />
          <a-button v-if="!disabled" @click="check" type="link" icon="check" >Confirmar</a-button>
        </div>
        <div v-else class="editable-cell-text-wrapper">
          <p v-if="value" class="text-sky-400/100">{{ value }}</p>
          <p v-else class="text-gray-300 opacity-100">{{ placeholder }}</p>
          <a-icon v-if="!disabled" type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
</template>


<script>

export default {
  props: {
    text: String,
    maxLength: Number,
    placeholder: String,
    disabled: Boolean
  },
  data() {
    return {
      value: this.text,
      editable: false,
      pp: this.placeholder
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit('change', this.value);
    },
    edit() {
      this.editable = true;
    },
  },
}

</script>