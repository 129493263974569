<template>
    <div class="fabs" role="group" aria-label="Floating action buttons">

        <a-button :type="atype" :html-type="type" shape="round" :class="classObject" size="large" :loading="loading"
            :disabled="disable" v-on:click="saveDraft">
            <b class="inline mx-4 font-bold text-xs">{{ label }}</b>
        </a-button>

    </div>
</template>
 
<script>
export default {
    props: ['type', 'atype', 'label', 'invert', 'danger', 'success', 'disable', 'loading'],
    computed: {
        classObject: function () {
            // return;
            let classes = ['border-blue', 'bg-blue', 'text-white', 'hover:bg-blue-dark', 'hover:text-white', 'hover:border-blue-dark']
            if (this.invert) {
                classes = ['border-blue', 'bg-white', 'text-blue', 'hover:bg-blue', 'hover:text-white']
            } else if (this.danger) {
                classes = ['border-red-600', 'bg-white', 'text-red-600', 'hover:bg-red-600', 'hover:text-white']
            } else if (this.success) {
                classes = ['border-green-600', 'bg-green-600', 'text-white', 'hover:bg-green-700', 'hover:border-green-700', 'hover:text-white']
            } else if (this.disable) {
                classes = ['border-gray-400', 'bg-gray-400', 'text-white', 'pointer-events-none']
            }
            return classes;
        }
    }, methods: {
        saveDraft() {
            this.$emit("saveDraft");
        }

    }
}
</script>
 
<style>
.fabs {
    z-index: 2;
    position: fixed;
    top: 25%;
    right: 40px;
}
</style>