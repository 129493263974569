<template>
  <div class="w-full mt-5 flex flex-col border-0 p-0" @click="openMultiSelect">
    <label class="mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80">
      <b>{{ title }}</b><span v-if="required" class="text-red-500">*</span>
    </label>
    <multi-select ref="multiSelect" :required="required" class="ant-select uppercase" name="false" size="large" v-model="values"
                  @close="emitUpdate"
                  :options="options" :filters="filters" :btnLabel="btnLabel" search historyButton :searchPlaceholder="search"
                  :selectOptions="data">
      <div class="h-10"></div>
      <template v-slot:option="{ option }" class="h-60">
        <div class="item-list">
          <input type="checkbox" :checked="option.selected" class="mr-1 appearance-none checked:bg-purple-600"/>
          <span data-bs-toggle="tooltip" :title="option[options.labelName]" class="ml-2 uppercase">
            {{ option[options.labelName] }}
          </span>
        </div>
      </template>
    </multi-select>
  </div>
</template>

<script>
import { update } from 'lodash';

export default {
  name: "ReasonsMultiSelect",
  props: {
    title: String,
    data: {
      type: Array,
      default: () => [],
    },
    filter: Boolean,
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
    defaultValues: {
      type: Array,
      default: () => [],
    },
    soloPreview: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      defaultValuesObjs: [],
      search: "Buscar...",
      btnLabel: (values) => `${values.length} ELEMENTOS SELECCIONADOS`,
      values: [],
      options: {
        multi: true,
        labelName: this.$props.label,
      },
      filters: [
        {
          nameAll: "Seleccionar todo",
          nameNotAll: "Deseleccionar todo",
          func() {
            return true;
          },
        },
      ],
    };
  },
  created() {

    if(this.defaultValues){
      
      this.defaultValuesObjs = this.data.filter((element) =>
        this.defaultValues.includes(String(element.id))
      );
      this.values = [...this.defaultValuesObjs ]
    }

  },
  watch: {
    defaultValues: {
      handler: function (values) {
        const data = values.map((val) => parseInt(val));

        this.defaultValuesObjs = this.data.filter((element) =>
          data.includes(element.id)
        );
        this.values = [...this.defaultValuesObjs ]

      },
      deep: true,
    },
    data:{
      handler: function () {
        this.defaultValuesObjs = this.data.filter((element) =>
          this.defaultValues.includes(String(element.id))
        );
        this.values = [...this.defaultValuesObjs ]
      },
      deep: true,
    }
  },
  methods: {
    Search() {
      return "";
    },
    openMultiSelect(){
      if(this.soloPreview){
        this.$refs['multiSelect'].openMultiSelect();
        this.values = [ ...this.defaultValuesObjs ]
      }
    },
    emitUpdate(){
      this.$emit('update-motivo', this.values.map((val) => val.id.toString()))
    }
  },
};
</script>

<style>
.item-list>input[type="checkbox"] {
  border: none;
  background-color: transparent;
  display: none;
  color: #000;
}

.item-list>input[type="checkbox"]:checked {
  border: none;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 13 13' fill='rgb(87, 93, 227)' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  display: inline-block;
}
</style>

<style>
.select[data-v-0ea235da] {
  display: inline-block;
  text-align: left;
  position: relative;
}

.select .vertical[data-v-0ea235da] {
  float: none;
}

/* ! horizontal layout */
.select .horizontal[data-v-0ea235da]:not(.selectGroup) {
  float: left;
}

/* ! create a "row" */
.select .line[data-v-0ea235da] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  max-height: 30px;
  overflow: hidden;
  padding: 2px 0 4px 0;
  position: relative;
}

/* ! create a "column" */
.select .acol[data-v-0ea235da] {
  display: inline-block;
  min-width: 12px;
}

/* ! */
.select .inlineBlock[data-v-0ea235da] {
  display: inline-block;
}

.select>button[data-v-0ea235da] {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  color: #555;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  min-height: 31px;
  padding: 1px 8px 1px 8px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  white-space: normal;
  background-color: #fff;
}

.select>button[data-v-0ea235da]:disabled,
.select>button[data-v-0ea235da]:hover {
  background-color: #f7f7f7;
}

.select .buttonClicked[data-v-0ea235da] {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset,
  0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.select .buttonLabel[data-v-0ea235da] {
  word-break: break-word;
  display: inline-block;
  padding: 0 0 0 0;
}

.select .caret[data-v-0ea235da] {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 0 1px 12px;
  vertical-align: middle;
  border-top: 4px solid #333;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 0 dotted;
}

.select .checkboxLayer[data-v-0ea235da] {
  background-color: #fff;
  position: absolute;
  z-index: 999;
  border: solid #d3d3d3;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  min-width: 278px;
  display: none;
}

.select .helperContainer[data-v-0ea235da] {
  padding: 8px 8px 0 8px;
}

.select .helperButton[data-v-0ea235da]:not(.reset) {
  margin-right: 4px;
}

.select .helperButton[data-v-0ea235da] {
  margin: 0 0 8px 0;
}

.select .helperButton[data-v-0ea235da],
.select .historyButton[data-v-0ea235da] {
  display: inline;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 26px;
  font-size: 13px;
  border-radius: 2px;
  background-color: #fff;
  line-height: 1.6;
}

.right[data-v-0ea235da],
.select .historyButton[data-v-0ea235da] {
  float: right;
}

.margin-right-10[data-v-0ea235da] {
  margin-right: 0;
}

@-moz-document url-prefix() {
  .select .clearButton {
    height: 24px !important;
  }
}

.select .clearButton[data-v-0ea235da] {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  position: absolute;
  display: inline;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 22px;
  width: 22px;
  font-size: 13px;
  border-radius: 2px;
  background-color: #fff;
  line-height: 1.4;
  right: 0;
  top: 2px;
}

.select .inputFilter[data-v-0ea235da] {
  border-radius: 2px;
  border: 1px solid #ccc;
  height: 26px;
  font-size: 14px;
  width: 100%;
  min-width: 320px;
  padding-left: 7px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 8px 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.select .clearButton[data-v-0ea235da]:hover,
.select .helperButton[data-v-0ea235da]:hover,
.select .historyButton[data-v-0ea235da]:hover {
  border: 1px solid #ccc;
  color: #999;
  background-color: #f1f1f1;
}

.select .clearButton[data-v-0ea235da]:focus,
.select .helperButton[data-v-0ea235da]:focus,
.select .historyButton[data-v-0ea235da]:focus,
.select .inputFilter[data-v-0ea235da]:focus {
  border: 1px solid #999;
  outline: 0;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.065),
  0 0 5px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.065),
  0 0 5px rgba(102, 175, 233, 0.6);
}

.select .checkBoxContainer[data-v-0ea235da] {
  display: block;
  padding-top: 5px;
  overflow: hidden;
  max-height: 300px;
  min-height: 80px;
  overflow-y: auto;
}

.margin-left-20[data-v-0ea235da] {
  margin-left: 0.7em;
}

/* ! to show / hide the checkbox layer above */
.select .show[data-v-0ea235da] {
  display: block;
}

.select .selectItem[data-v-0ea235da] {
  font-size: 13px;
  margin-top: 0;
}

.select .selectItem[data-v-0ea235da],
.select .selectItemDeactive[data-v-0ea235da] {
  display: flex;
  padding: 3px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  position: relative;
  min-width: 278px;
  min-height: 32px;
}

.select .selectItemDeactive[data-v-0ea235da] {
  color: #444;
}

.select .selectItemDeactive[data-v-0ea235da]:hover {
  cursor: not-allowed;
}

.select .selectItem.selected[data-v-0ea235da] {
  background-color: #e9e9e9;
  color: #555;
  cursor: pointer;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #d9d9d9;
}

.select .selectItem .acol label[data-v-0ea235da] {
  display: inline-block;
  padding-right: 30px;
  margin: 0;
  font-weight: 400;
  line-height: normal;
}

.select .selectGroup[data-v-0ea235da]:hover,
.select .selectItem[data-v-0ea235da]:hover {
  cursor: pointer;
  background-color: #f5f7fa;
}

.select .selectFocus[data-v-0ea235da],
.select .selectGroup span[data-v-0ea235da]:hover,
.select .selectItem span[data-v-0ea235da]:hover {
  cursor: pointer;
}

/* ! group labels */
.select .selectGroup[data-v-0ea235da] {
  display: block;
  clear: both;
}

.select .tickMark[data-v-0ea235da] {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 10px;
}

.select .checkbox[data-v-0ea235da] {
  color: #ddd;
  position: absolute;
  left: -9999px;
  cursor: pointer;
}

.select .disabled[data-v-0ea235da],
.select .disabled[data-v-0ea235da]:hover,
.select .disabled label input:hover~span[data-v-0ea235da] {
  color: #c4c4c4;
  cursor: not-allowed;
}

.select img[data-v-0ea235da] {
  vertical-align: middle;
  margin-bottom: 0;
  max-height: 22px;
  max-width: 22px;
}

.select .group[data-v-0ea235da] {
  font-weight: 600;
  font-size: 14px;
}

.select .sousGroup[data-v-0ea235da] {
  margin-left: 15px;
}

.tab[data-v-0ea235da] {
  padding-left: 0;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 0.05rem solid #e7e9ed;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0.2rem 0 0.15rem 0;
}

.tab-item[data-v-0ea235da] {
  margin-top: 0;
}

.tab .tab-item.active span[data-v-0ea235da],
.tab .tab-item span.active[data-v-0ea235da] {
  border-bottom-color: #6e7991;
  color: #6e7991;
}

.tab .tab-item span[data-v-0ea235da] {
  border-bottom: 0.1rem solid transparent;
  color: inherit;
  display: block;
  margin: 0 0.4rem 0 0;
  padding: 0.4rem 0.4rem 0.3rem 0.4rem;
  text-decoration: none;
}

.select .empty-tab[data-v-0ea235da] {
  min-height: 80px;
  text-align: center;
  padding-top: 30px;
  font-size: 15px;
}

.selectList[data-v-0ea235da] {
  margin: 0;
  list-style: inside disc;
  padding-left: 0;
}

.pointer[data-v-0ea235da] {
  cursor: pointer;
}

.bold[data-v-0ea235da] {
  font-weight: 700;
}

.select .show[data-v-0ea235da] {
  display: block;
  width: inherit;
}

.select .inputFilter[data-v-0ea235da] {
  border-radius: 7em;
  padding-left: 1em;
}

.select .clearButton[data-v-0ea235da] {
  margin-top: 1px;
}
</style>

<style>
.flex .select .helperButton {
  padding: 0 1em;
  border-radius: 0.5em;
}

.flex .right,
.select .historyButton {
  float: right;
  padding: 0 1em;
  border-radius: 0.5em;
}
</style>
