export default [
  {
    id: "a",
    boolValue: false,
    name: "a. No corresponde al giro ordinario de las operaciones de la entidad",
  },
  {
    id: "b",
    boolValue: false,
    name: "b. Se refiere al vínculo laboral entre la entidad y sus empleados o contratistas",
  },
  {
    id: "c",
    boolValue: false,
    name: "c. Se deriva de condición de accionista de la entidad",
  },
  {
    id: "d",
    boolValue: false,
    name: "d. Se refiere al reconocimiento de las prestaciones y de las pensiones de invalidez, de vejez y de sobrevivientes",
  },
  {
    id: "e",
    boolValue: false,
    name: "e. Se encuentra en trámite judicial o arbitral o hayan sido resueltas en estas vías",
  },
  {
    id: "f",
    boolValue: false,
    name: "f. Corresponde a la decisión sobre la prestación de un servicio o producto",
  },
  {
    id: "g",
    boolValue: false,
    name: "g. Se refieren a hechos sucedidos con tres (3) años o más de anterioridad",
  },
  {
    id: "h",
    boolValue: false,
    name: "h. Han sido objeto de decisión previa por parte del Defensor por tener mismos hechos y afectar a las mismas partes",
  },
  {
    id: "i",
    boolValue: false,
    name: "i. Cuantía supera los cien (100) SMLMV",
  },
  {
    id: "j",
    name: "j. Otra",
    boolValue: true,
  },
];
