<template>
    <div>
        <!--  **************************************  START STEP 2  *********************************************  -->
        <Accordion :open="openAccordions.acc_2" :disable="false" key="astep2" class="px-0"
            header="2. De la función de atención al consumidor financiero">

            <div class="w-full grid grid grid-cols-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4">
                <BaseRadio ref="dcf_atendio_solicitudes" name="ar_type" v-model="mainData.dcf_atendio_solicitudes"
                    label="¿La DCF atendió peticiones o solicitudes formuladas por los consumidores (diferentes a vocerías, quejas y conciliaciones)?"
                    @onChangeValueGetValue="onChangeRadio" :radioOptions="options.oYesOrNot" required="true"
                    class="col-span-3" :disabled="soloPreview"/>
            </div>

            <div v-if="mainData.dcf_atendio_solicitudes" class="w-full grid grid grid-cols-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4">
                
                <BaseNumber ref="many_dcf_atendio_solicitudes" class="col-span-3"
                    label="Número total de peticiones y solicitudes formuladas por los consumidores (diferentes a vocerías, quejas y conciliaciones)."
                    placeholder="Ej. 2341234"
                    v-model="mainData.numero_total_peticiones" :disabled="soloPreview"
                />

                <!--  **************************************  START STEP 2.1  *********************************************  -->
                <Accordion :open="openAccordions.acc_21" :disable="false" key="astep21" class="px-0 col-start-1 col-span-4"
                    header="2.1 Peticiones y solicitudes formuladas por los consumidores que se atendieron directamente en la DCF  (diferentes a vocerías, quejas y conciliaciones).">
                    

                    <BaseNumber ref="many21_dcf_atendio_solicitudes" class="col-span-1"
                        label="Número total de peticiones y solicitudes formuladas por los consumidores, atendidas directamente en la DCF (diferentes a vocerías, quejas y conciliaciones)"
                        placeholder="Ej. 2341234"
                        v-model="mainData.paso_2_1.numero_total_peticiones" :disabled="soloPreview"
                    />

                    <BaseTextarea
                        v-model="mainData.paso_2_1.descripcion"
                        :disabled="soloPreview"
                        class="col-start-1 col-span-2"
                        ref="describe"
                        id="describe"
                        label="Resuma las principales temáticas de las peticiones y solicitudes formuladas por los consumidores, que se atendieron directamente en la DCF"
                        placeholder="Mensaje"
                        maxlength="1000"
                    />    

                </Accordion>

                <!--  **************************************  START STEP 2.2  *********************************************  -->
                <Accordion :open="openAccordions.acc_22" :disable="false" key="astep22" class="px-0 col-start-1 col-span-4"
                    header="2.2 Peticiones y solicitudes recibidas por el DFC (diferentes a vocerías, quejas y conciliaciones) en las que no asumió conocimiento por falta de competencia del defensor o de la entidad">

                    <BaseNumber ref="many_dcf_atendio_solicitudes" class="col-span-3"
                        label="Número total de peticiones y solicitudes formuladas por los consumidores (diferentes a vocerías, quejas y conciliaciones) que no atendió la DCF ni trasladó a la EV por falta de competencia."
                        placeholder="Ej. 2341234"
                        v-model="mainData.paso_2_2.numero_total_peticiones"
                        :disabled="soloPreview"
                    />

                    <BaseTextarea
                        v-model="mainData.paso_2_2.descripcion"
                        :disabled="soloPreview"
                        class="col-start-1 col-span-2"
                        ref="describe"
                        id="describe"
                        label="Temáticas: Identifique las principales temáticas de peticiones y solicitudes que no sean de competencia de la DCF o de la entidad vigilada. Incluyendo el número por temática."
                        placeholder="Mensaje"
                        maxlength="2000"
                    />

                </Accordion>

                <!--  **************************************  START STEP 2.3  *********************************************  -->
                <Accordion :open="openAccordions.acc_23" :disable="false" key="astep23" class="px-0 col-start-1 col-span-4"
                    header="2.3 Peticiones y solicitudes formuladas por los consumidores (diferentes a vocerías, quejas y conciliaciones) que se trasladaron a la EV para su conocimiento.">


                    <BaseInput ref="Número total de peticiones y solicitudes formuladas por los consumidores (diferentes a vocerías, quejas y conciliaciones) que se trasladaron a la EV para su conocimiento." type="number"
                        label="Número total de peticiones y solicitudes formuladas por los consumidores (diferentes a vocerías, quejas y conciliaciones) que se trasladaron a la EV para su conocimiento." required="true"
                        v-model="mainData.paso_2_3.numero_total_peticiones"
                        :disabled="soloPreview" />
                
                    <BaseTextarea class="col-start-1 col-span-2" ref="describe_1.2.3" id="describe_1.2.3"
                        label="Presente y describa las situaciones, productos y tipologías de este tipo de trámites más destacados que recibió y fueron trasladadas a la EV"
                        placeholder="" maxlength="2000" required="true"
                        v-model="mainData.paso_2_3.descripcion"
                        :disabled="soloPreview" />
                    
                </Accordion>
            </div>

        </Accordion>
    </div>
</template>


<script>
import { mapState } from "vuex";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import BaseNumber from "../../../../../components/UI/BaseInput/BaseNumber.vue";
import ProductReasonsField from "@/feature/annual_report_v2/presentation/components/ProductReasonsField";
import TemplateProductInputs from "@/feature/annual_report/presentation/views/components/template_product_inputs.vue";
import ProductReasonsWithTableField from "@/feature/annual_report_v2/presentation/components/ProductReasonsWithTableField";


export default {
    components: {
        Accordion,
        TemplateProductInputs,
        ProductReasonsField,
        ProductReasonsWithTableField
    },
    props: {
        initialData: {
            type: Object,
            default: null,
        },
        soloPreview: {
            type: Boolean,
            default: false
        },
        company: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            mainData:{
                dcf_atendio_solicitudes: null,
                numero_total_peticiones: null,
                paso_2_1: {
                    numero_total_peticiones: null,
                    productos_motivos: [],
                    descripcion: "" // str: 1000
                },
                paso_2_2: {
                    numero_total_peticiones: null,
                    productos_motivos: [],
                    descripcion: "" // str: 2000
                },
                paso_2_3: {
                    numero_total_peticiones: null,
                    productos_motivos: [],
                    descripcion: "" // str: 1000
                }
            },
            openAccordions:{
                acc_2: true,
                acc_21: false,
                acc_22: false,
                acc_23: false,
            },
            options: {
                oYesOrNot: [
                    { id: true, name: "Sí" },
                    { id: false, name: "No" },
                ],
            }
        }
    },
    watch: {
        "mainData.paso_2_1.numero_total_peticiones": {
            handler(newVal) {
                console.log(newVal)
            }
        },
        initialData: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if(newVal != null){
                    this.mainData = newVal;
                    console.log('this.mainData----', this.mainData)
                    if(!this.isFisrtTime && !this.hasChanged){
                        this.hasChanged = true;
                        console.log('mainData 2 hasChanged:', this.hasChanged)
                    }
                    this.isFisrtTime = false
                }
            }
        }
    },
    created() {
        if(this.initialData == null){
            this.hasChanged = true;
        }
        this.mainData = this.initialData ?? this.mainData;
    },
    methods: {
        notifyChanges(){
            let error = false;
            // Se implementa lógica antes de guardar la info, en caso de ser necesario
            console.log('Entrando en notifyChanges en paso 2...')
            return {error: error, data: this.mainData};
        },
        onChangeRadio(){},
        getProductReasons(value) {
            console.log('Value of products', value)
            if(value.clave == "step_2_1"){
                delete value.clave;
                this.mainData.paso_2_1.productos_motivos = [...value];
            }
            if(value.clave == "step_2_2"){
                delete value.clave;
                this.mainData.paso_2_2.productos_motivos = [...value];
            }
            if(value.clave == "step_2_3"){
                delete value.clave;
                this.mainData.paso_2_3.productos_motivos = [...value];
            }
        },
    }
};
</script>