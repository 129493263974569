var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full mt-10 mb-10"},[_c('Accordion',{key:"step-1.1",attrs:{"open":_vm.open,"disable":!_vm.open,"header":"1.3 Relacionamiento entre la entidad y el defensor"}},[_c('h2',{staticClass:"mt-10 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.3.1 Reporte reincidencias ")]),_c('BaseRadio',{ref:"step_131",staticClass:"mt-10 mb-8",attrs:{"name":"step_131","label":"Se presentaron situaciones de incumplimiento parcial o total por parte de la entidad con posterioridad a los ajustes o a la rectificación en el período reportado","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_131')},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificación_reportado
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificación_reportado", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificación_reportado\n      "}}),(
        _vm.mainData.step_1_3
          ._1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificación_reportado ==
        1
      )?_c('div',[_c('BaseInput',{ref:"Número total de reincidencias registradas",attrs:{"type":"number","label":"Número total de reincidencias","required":"true","disabled":_vm.soloPreview},model:{value:(
          _vm.mainData.step_1_3._1_3_1_total_decision_emitida_favor_entidad
        ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3, "_1_3_1_total_decision_emitida_favor_entidad", $$v)},expression:"\n          mainData.step_1_3._1_3_1_total_decision_emitida_favor_entidad\n        "}}),_c('BaseTextarea',{ref:"describe_1.2.2.2",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe_1.2.2.2","label":"Presente y describa las situaciones, productos y tipologías de quejas en las cuales en mayor medida se presentan reincidencias por parte de la entidad","placeholder":"","maxlength":"2000","required":"true","disabled":_vm.soloPreview},model:{value:(
          _vm.mainData.step_1_3
            ._1_3_1_tipologia_describa_situaciones_DCF_considere_incumplimiento
        ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
            , "_1_3_1_tipologia_describa_situaciones_DCF_considere_incumplimiento", $$v)},expression:"\n          mainData.step_1_3\n            ._1_3_1_tipologia_describa_situaciones_DCF_considere_incumplimiento\n        "}}),_c('BaseTextarea',{ref:"describe_1.2.52",staticClass:"mt-10",attrs:{"id":"describe_1.2.52","label":"Describa las situaciones que la DCF considere de incumplimiento (parcial o total) por parte de la entidad vigilada después de haber efectuado, ajustes o rectificaciones, sin hacer referencia a casos particulares.","placeholder":"","maxlength":"500","disabled":_vm.soloPreview},model:{value:(
          _vm.mainData.step_1_3
            ._1_3_1_describa_situaciones_DCF_considere_incumplimiento
        ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
            , "_1_3_1_describa_situaciones_DCF_considere_incumplimiento", $$v)},expression:"\n          mainData.step_1_3\n            ._1_3_1_describa_situaciones_DCF_considere_incumplimiento\n        "}})],1):_vm._e(),_c('h2',{staticClass:"mt-10 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.3.2 Reporte de situaciones relevantes ")]),_c('h2',{staticClass:"mt-10 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.3.2.1 Casos en que la entidad consideró que el DCF carecía de competencia ")]),_c('BaseRadio',{ref:"step_1321",staticClass:"mt-20 mb-10",attrs:{"name":"step_1321","label":"¿Se presentaron casos en los que la entidad consideró que el DCF carecía de competencia?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1321')},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia\n      "}}),(
        _vm.mainData.step_1_3
          ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
        1
      )?_c('BaseInput',{ref:"total_casos_entidad_considero_DCF_no_competente",staticClass:"w-50 mt-10",attrs:{"label":"Número total de casos en los que la entidad consideró que el DCF no era competente","type":'number',"numeric":true,"disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_1_total_casos_entidad_considero_DCF_no_competente
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_1_total_casos_entidad_considero_DCF_no_competente", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_1_total_casos_entidad_considero_DCF_no_competente\n      "}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
        1
      )?_c('BaseRadio',{ref:"step_13211",staticClass:"mt-10 mb-10",attrs:{"name":"step_13211","label":"¿La defensoría estuvo de acuerdo con la totalidad de los casos en los cuales la entidad justificó causales de no competencia para la DCF?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_13211')},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia\n      "}}):_vm._e(),(
        !(
          _vm.mainData.step_1_3
            ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
          1
        )
      )?_c('BaseTextarea',{ref:"describe_1.3.211",staticClass:"mt-10",attrs:{"id":"describe_1.3.211","label":"Describa brevemente las razones por las cuales el DCF no compartió la posición de la entidad sobre su competencia. Por favor no incluir casos ni quejas individuales.","placeholder":"","maxlength":"400","disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_1_describa_DCF_no_compartio_entidad_competencia_no_casos_ni_quejas_individuales
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_1_describa_DCF_no_compartio_entidad_competencia_no_casos_ni_quejas_individuales", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_1_describa_DCF_no_compartio_entidad_competencia_no_casos_ni_quejas_individuales\n      "}}):_vm._e(),(
        !(
          _vm.mainData.step_1_3
            ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
          1
        )
      )?_c('BaseTextarea',{ref:"describe_1.3.211",staticClass:"mt-10",attrs:{"id":"describe_1.3.211","label":"Indique si el DCF reportó o expresó sus consideraciones a la entidad y de qué forma lo hizo (Detalle la información de fechas y área(s) de la entidad a la cual se le hizo el reporte)","placeholder":"","maxlength":"400","disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_1_indique_DCF_reporto_consideraciones_entidad_de_que_forma_hizo
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_1_indique_DCF_reporto_consideraciones_entidad_de_que_forma_hizo", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_1_indique_DCF_reporto_consideraciones_entidad_de_que_forma_hizo\n      "}}):_vm._e(),(
        !(
          _vm.mainData.step_1_3
            ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
          1
        )
          ? false
          : _vm.mainData.step_1_3
              ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
            1
      )?_c('BaseInput',{ref:"total_quejas_asociadas_justificacion",attrs:{"type":"text","label":"Número total de quejas asociadas a la justificación","required":true,"type":'number',"numeric":true,"disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3._1_3_2_1_total_quejas_asociadas_justificacion
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3, "_1_3_2_1_total_quejas_asociadas_justificacion", $$v)},expression:"\n        mainData.step_1_3._1_3_2_1_total_quejas_asociadas_justificacion\n      "}}):_vm._e(),(
        !(
          _vm.mainData.step_1_3
            ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
          1
        )
          ? false
          : _vm.mainData.step_1_3
              ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
            1
      )?_c('BaseCheckboxVertical',{staticClass:"col-start-1 col-span-4 mb-10",attrs:{"label":"Justificación de la no competencia según la entidad","value":_vm.mainData.step_1_3
          ._1_3_2_1_justificacion_no_competencia_segun_entidad_ids,"data":_vm.options.oJustInadmision,"disabled":_vm.soloPreview},on:{"notify-changes":_vm.onChangeSelectJustInadmision}}):_vm._e(),_c('div',{staticClass:"col-span-2"}),(
        _vm.mainData.step_1_3
          ._1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia ==
        2
          ? false
          : _vm.mainData.step_1_3
              ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
            1
      )?_c('div',[(_vm.isJustInadActive('1'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"1) Causal legal de exclusión de competencia considerada por la entidad"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad1 = !_vm.openAccordions.showJustInad1}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad1 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad1)?_c('div',[_c('BaseNumber',{ref:"num_12__numero_total_solicitudes",attrs:{"label":"Número total de inadmitidas asociadas a la justificación seleccionada.","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
              _vm.mainData.step_1_3._1_3_2_1_causal_legal_numero_total_solicitudes
            ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3, "_1_3_2_1_causal_legal_numero_total_solicitudes", $$v)},expression:"\n              mainData.step_1_3._1_3_2_1_causal_legal_numero_total_solicitudes\n            "}}),_c('a-collapse',{staticClass:"mt-6"},[_c('a-collapse-panel',{key:"1",attrs:{"header":"Causales"}},[_c('p',{staticClass:"text-opacity-80",class:_vm.mainData.step_1_3
                    ._1_3_2_1_causal_legal_numero_total_solicitudes !=
                  _vm.totalCausales_1_3_2_1
                    ? 'text-red-500'
                    : 'text-green-500',attrs:{"slot":"extra"},slot:"extra"},[_vm._v(" Total: "+_vm._s(_vm.totalCausales_1_3_2_1)+" "+_vm._s(_vm.mainData.step_1_3 ._1_3_2_1_causal_legal_numero_total_solicitudes != _vm.totalCausales_1_3_2_1 ? "(No coinciden los totales)" : "")+" ")]),_c('a-table',{attrs:{"size":"small","pagination":false,"row-selection":_vm.rowSelection,"columns":_vm.columnsCausal,"data-source":_vm.options.oTipoJustInCausaLegal},scopedSlots:_vm._u([{key:"labelName",fn:function(text, record){return [_c('a',{staticClass:"flex text-left"},[_vm._v(_vm._s(text))])]}},{key:"count",fn:function(text, record){return [(_vm.isRowSelected(record.id))?_c('BaseNumber',{ref:"num_31_numero_total_solicitudes",staticClass:"col-start-1 col-span-2 mt-0 pt-0",attrs:{"value":_vm.valueCausal(record.id),"label":"Digita una cantidad","placeholder":"Ej: 123","mt_disabled":true,"disabled":_vm.soloPreview},on:{"notify-change":function($event){return _vm.updateDetail(record.id, $event)}}}):_vm._e()]}}],null,false,3058617260)})],1)],1),_c('BaseRadio',{ref:"opt_causal_legal_aplica_producto_motivo",staticClass:"col-start-1 col-span-2",attrs:{"label":"¿Aplica producto y motivo?","radioOptions":_vm.options.oYesOrNot,"disabled":_vm.soloPreview},model:{value:(
              _vm.mainData.step_1_3._1_3_2_1_causal_legal_aplica_producto_motivo
            ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3, "_1_3_2_1_causal_legal_aplica_producto_motivo", $$v)},expression:"\n              mainData.step_1_3._1_3_2_1_causal_legal_aplica_producto_motivo\n            "}}),(_vm.causalLegalIncludeOtra())?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-4",attrs:{"id":"describe","label":"Descripción","placeholder":"Mensaje","maxlength":"50","disabled":_vm.soloPreview},model:{value:(_vm.mainData.step_1_3._1_3_2_1_causal_legal_otra_descripcion),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3, "_1_3_2_1_causal_legal_otra_descripcion", $$v)},expression:"mainData.step_1_3._1_3_2_1_causal_legal_otra_descripcion"}}):_vm._e(),(
              _vm.mainData.step_1_3
                ._1_3_2_1_causal_legal_aplica_producto_motivo == 1
            )?_c('ProductReasonsWithTableField',{ref:"pr_table_1_3_2_1",attrs:{"name":"pr_table_1_3_2_1","company_id":_vm.company.company_id,"labelReasonsCounter":'Número de queja de la no competencia según motivo seleccionado',"labelProductCounter":'Número de queja de la no competencia según producto seleccionado',"products_reasons":_vm.mainData.step_1_3._1_3_2_1_producto_motivo,"hideTopInputs":true,"soloPreview":_vm.soloPreview},on:{"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_1_producto_motivo", $event)}}}):_vm._e(),(
              _vm.mainData.step_1_3
                ._1_3_2_1_justificacion_inadmision_causal_legal_aplica_producto_motivo
            )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-4",attrs:{"id":"describe","label":"Descripción","placeholder":"Mensaje","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(_vm.mainData.step_1_3._1_3_2_1_descripcion),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3, "_1_3_2_1_descripcion", $$v)},expression:"mainData.step_1_3._1_3_2_1_descripcion"}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.isJustInadActive('2'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"2) Se refiere a otra entidad vigilada"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad2 = !_vm.openAccordions.showJustInad2}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad2 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad2)?_c('div',[_c('BaseNumber',{ref:"num_13_numero_total_solicitudes",staticClass:"col-start-1 col-span-2",attrs:{"label":"Número total inadmitidas asociadas a la justificación seleccionada.","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
              _vm.mainData.step_1_3
                ._1_3_2_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes
            ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
                , "_1_3_2_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes", $$v)},expression:"\n              mainData.step_1_3\n                ._1_3_2_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes\n            "}})],1):_vm._e()],1):_vm._e(),(_vm.isJustInadActive('3'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"3) Solicitud incompleta, no clara, lenguaje soez o agresivo"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad3 = !_vm.openAccordions.showJustInad3}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad3 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad3)?_c('div',[_c('BaseNumber',{ref:"num_12_numero_total_solicitudes",staticClass:"col-start-1 col-span-2",attrs:{"label":"Número total inadmitidas asociadas a la justificación seleccionada.","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
              _vm.mainData.step_1_3
                ._1_3_2_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes
            ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
                , "_1_3_2_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes", $$v)},expression:"\n              mainData.step_1_3\n                ._1_3_2_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes\n            "}})],1):_vm._e()],1):_vm._e(),(_vm.isJustInadActive('4'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"4) Otra"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad4 = !_vm.openAccordions.showJustInad4}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad4 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad4)?_c('div',[_c('BaseNumber',{ref:"num_12_numero_total_solicitudes",staticClass:"col-start-1 col-span-2",attrs:{"label":"Número total de solicitudes de conciliación inadmitidas asociadas a la justificación seleccionada.","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
              _vm.mainData.step_1_3
                ._1_3_2_1_justificacion_inadmision_otra_numero_total_solicitudes
            ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
                , "_1_3_2_1_justificacion_inadmision_otra_numero_total_solicitudes", $$v)},expression:"\n              mainData.step_1_3\n                ._1_3_2_1_justificacion_inadmision_otra_numero_total_solicitudes\n            "}}),_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-4",attrs:{"id":"describe","label":"Informe brevemente y de manera consolidada las principales temáticas de las solicitudes de conciliación que fueron inadmitidas por la DCF. (Por favor no incluir casos individuales)","placeholder":"Escribe la descripción...","maxlength":"50","disabled":_vm.soloPreview},model:{value:(
              _vm.mainData.step_1_3
                ._1_3_2_1_justificacion_inadmision_otra_descripcion
            ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
                , "_1_3_2_1_justificacion_inadmision_otra_descripcion", $$v)},expression:"\n              mainData.step_1_3\n                ._1_3_2_1_justificacion_inadmision_otra_descripcion\n            "}})],1):_vm._e()],1):_vm._e()],1):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia ==
        1
      )?_c('BaseTextarea',{ref:"describe_step_13211_1_mp",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_step_13211_1_mp","label":"Resuma las situaciones en las que la EV consideró que la DCF no era competente, priorizando las más representativas. No incluir casos particulares.","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_1_resuma_situaciones_EV_considero_DCF_no_competente
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_1_resuma_situaciones_EV_considero_DCF_no_competente", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_1_resuma_situaciones_EV_considero_DCF_no_competente\n      "}}):_vm._e(),_c('h2',{staticClass:"mt-10 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.3.2.2 Colaboración de la entidad con el DCF ")]),_c('BaseRadio',{ref:"entidad_colaboró_suministró_información",staticClass:"mt-10 mb-10",attrs:{"name":"entidad_colaboró_suministró_información","label":"¿La entidad colaboró con el DCF o suministró la información requerida?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1322')},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida\n      "}}),(
        _vm.mainData.step_1_3
          ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida == 2
      )?_c('BaseInput',{ref:"total_entidad_no_suministró_información",staticClass:"mb-10",attrs:{"type":"text","label":"Número total de eventos en las que la entidad no suministró la información requerida por la DCF","required":true,"disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_2_total_eventos_entidad_no_suministro_informacion_requerida_DCF
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_2_total_eventos_entidad_no_suministro_informacion_requerida_DCF", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_2_total_eventos_entidad_no_suministro_informacion_requerida_DCF\n      "}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida == 2
      )?_c('ProductReasonsWithTableField',{ref:"pr_table_1_3_2_2",attrs:{"name":"pr_table_1_3_2_2","company_id":_vm.company.company_id,"labelReasonsCounter":'Número total de eventos en las que la entidad no suministró la información requerida por la DCF según motivo seleccionado',"labelProductCounter":'Número total de eventos en las que la entidad no suministró la información requerida por la DCF según producto seleccionado',"products_reasons":_vm.mainData.step_1_3._1_3_2_2_producto_motivo,"hideTopInputs":true,"soloPreview":_vm.soloPreview},on:{"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_2_producto_motivo", $event)}}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida == 2
      )?_c('BaseTextarea',{ref:"describe_step_1322",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_step_1322","label":"Resuma de manera consolidada las situaciones presentadas para que el Defensor considere que la entidad no colaboró con la Defensoría, priorizando aquellas más representativas y/o de mayor materialidad o impacto en el consumidor financiero. Por favor no incluir casos ni quejas individuales.","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_2_resuma_situaciones_defensor_entidad_no_colaboro_con_defensoria
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_2_resuma_situaciones_defensor_entidad_no_colaboro_con_defensoria", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_2_resuma_situaciones_defensor_entidad_no_colaboro_con_defensoria\n      "}}):_vm._e(),_c('h2',{staticClass:"mt-10 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.3.2.3 Aceptación de los pronunciamientos del DCF a favor del consumidor financiero, por parte de la entidad ")]),_c('BaseRadio',{ref:"DCF_permitio_pronunciamiento_a_favor_consumidor_financiero",staticClass:"mt-10 mb-10",attrs:{"name":"DCF_permitio_pronunciamiento_a_favor_consumidor_financiero","label":"¿El DCF emitió pronunciamientos a favor del consumidor financiero?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1323')},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general\n      "}}),(
        _vm.mainData.step_1_3
          ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        1
      )?_c('BaseInput',{ref:"total_decisiones_emitidas_a_favor_consumidor_financiero",staticClass:"mb-10",attrs:{"label":"Número total de decisiones emitidas a favor del consumidor financiero","required":true,"type":'number',"numeric":true,"disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_3_total_decisiones_emitidas_a_favor_consumidor_financiero
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_3_total_decisiones_emitidas_a_favor_consumidor_financiero", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_3_total_decisiones_emitidas_a_favor_consumidor_financiero\n      "}}):_vm._e(),(_vm.equalTotalEmitter)?_c('p',{staticClass:"text-xs text-red-500"},[_vm._v(" El número total de decisiones emitidas a favor del consumidor financiero (1.3.2.3) no coincide con la suma de las categorías \"totalmente a favor\" (1.2.5.1) y \"parcialmente a favor\" (1.2.5.2) . Por favor, revise los datos ingresados.” ")]):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        1
      )?_c('BaseCheckboxDisable',{attrs:{"options":_vm.options.checkOptions,"label":"¿Los pronunciamientos del DCF son obligatorios para la entidad?","showAll":false,"disabled":_vm.soloPreview},on:{"input":_vm.onChangeCheck}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        1
      )?_c('div',[(_vm.isChecks(1))?_c('div',[_c('a-table',{ref:'step_1323_1',attrs:{"columns":_vm.columns,"data-source":_vm.mainData.step_1_2._1_2_4_1_producto_motivo,"pagination":false,"scroll":{ x: '1220px' }}},[_c('div',{staticClass:"title-size font-bold text-gray-500 text-center",attrs:{"slot":"fecha_reporte"},slot:"fecha_reporte"},[_c('p',[_vm._v("Producto")])]),_c('div',{staticClass:"title-size font-bold text-gray-500",attrs:{"slot":"entidad_vigilada"},slot:"entidad_vigilada"},[_vm._v(" N° ")])]),_c('div',{staticClass:"grid grid-cols-2 gap-x-8 mb-10"},[_c('BaseInput',{ref:"decisiones_totalmente_favorables_consumidor_financiero",staticClass:"mb-10",attrs:{"label":"Número de decisiones totalmente  favorables al consumidor financiero.","type":'number',"numeric":true,"disabled":true,"value":_vm.mainData.step_1_2
                ._1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero}}),_c('BaseInput',{ref:"decisiones_parcialmente_favorables_consumidor_financiero",staticClass:"mb-10",attrs:{"label":"Número total de decisiones parcialmente favorables al consumidor financiero","type":'number',"numeric":true,"disabled":true,"value":_vm.mainData.step_1_2
                ._1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero}})],1)],1):_vm._e(),(_vm.isChecks(2))?_c('div',[_c('BaseInput',{ref:"total_pronunciamientos_obligatorios_acuerdo_previo_expreso",staticClass:"mb-10 w-50",attrs:{"label":"Número total de pronunciamientos obligatorios por acuerdo previo y expreso","type":'number',"numeric":true,"disabled":_vm.soloPreview},model:{value:(
            _vm.mainData.step_1_3
              ._1_3_2_3_2_total_pronunciamientos_obligatorios_acuerdo_previo_expreso
          ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
              , "_1_3_2_3_2_total_pronunciamientos_obligatorios_acuerdo_previo_expreso", $$v)},expression:"\n            mainData.step_1_3\n              ._1_3_2_3_2_total_pronunciamientos_obligatorios_acuerdo_previo_expreso\n          "}}),_c('ProductReasonsWithTableField',{ref:"pr_table_1_3_2_1_1_1",attrs:{"name":"pr_table_1_3_2_1_1_1","company_id":_vm.company.company_id,"labelReasonsCounter":'Número total pronunciamientos obligatorios por acuerdo previo y expreso según motivo seleccionado',"labelProductCounter":'Número total pronunciamientos obligatorios por acuerdo previo y expreso según  producto seleccionado',"products_reasons":_vm.mainData.step_1_3._1_3_2_1_1_1_producto_motivo,"hideTopInputs":true,"soloPreview":_vm.soloPreview},on:{"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_1_1_1_producto_motivo", $event)}}})],1):_vm._e(),(_vm.isChecks(3))?_c('div',[_c('BaseInput',{ref:"total_pronunciamientos_obligatorios_segun_criterio",staticClass:"mb-10 w-50",attrs:{"label":"Número total de pronunciamientos obligatorios según criterio","type":'number',"numeric":true,"disabled":_vm.soloPreview},model:{value:(
            _vm.mainData.step_1_3
              ._1_3_2_3_3_total_pronunciamientos_obligatorios_segun_criterio
          ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
              , "_1_3_2_3_3_total_pronunciamientos_obligatorios_segun_criterio", $$v)},expression:"\n            mainData.step_1_3\n              ._1_3_2_3_3_total_pronunciamientos_obligatorios_segun_criterio\n          "}}),_c('BaseTextarea',{ref:"informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF","label":"Informe los criterios establecidos por la entidad para aceptar como obligatorias las decisiones a favor del consumidor financiero emitidas por la DCF, tales como producto, motivo, cuantía, tipo de consumidor financiero o cualquier otra consideración","placeholder":"","maxlength":"2000","disabled":_vm.soloPreview},model:{value:(
            _vm.mainData.step_1_3
              ._1_3_2_3_3_informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF
          ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
              , "_1_3_2_3_3_informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF", $$v)},expression:"\n            mainData.step_1_3\n              ._1_3_2_3_3_informe_criterios_establecidos_entidad_obligatorias_decisiones_a_favor_consumidor_financiero_emitidas_DCF\n          "}})],1):_vm._e(),(_vm.isChecks(4))?_c('div',[_c('div',{staticClass:"w-50"},[_c('BaseInput',{ref:"total_pronunciamientos_no_obligatorios",staticClass:"mb-10 w-50",attrs:{"label":"Número total de pronunciamientos no obligatorios","type":'number',"numeric":true,"disabled":_vm.soloPreview},model:{value:(
              _vm.mainData.step_1_3
                ._1_3_2_3_4_total_pronunciamientos_no_obligatorios
            ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
                , "_1_3_2_3_4_total_pronunciamientos_no_obligatorios", $$v)},expression:"\n              mainData.step_1_3\n                ._1_3_2_3_4_total_pronunciamientos_no_obligatorios\n            "}})],1)]):_vm._e(),_c('BaseRadio',{ref:"entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor",staticClass:"mt-10 mb-10",attrs:{"name":"entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor","label":"¿La entidad le informó a la DCF sobre la aceptación o no de las decisiones del defensor a favor del consumidor?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1323_r')},model:{value:(
          _vm.mainData.step_1_3
            ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor
        ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
            , "_1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor", $$v)},expression:"\n          mainData.step_1_3\n            ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor\n        "}}),(
          !(
            _vm.mainData.step_1_3
              ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor ==
            1
          )
        )?_c('BaseTextarea',{ref:"describe_step_1323_no_informo",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_step_1323-no_informo","label":"Reporte brevemente qué tipo de actuaciones desplegó el DCF ante la omisión de información por parte de la entidad y, señale el estado actual de la interacción que sobre el particular se tiene con la entidad. (Por favor no incluir casos ni quejas individuales)","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
          _vm.mainData.step_1_3
            ._1_3_2_3_reporte_actuaciones_desplego_DCF_omision_informacion_entidad
        ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
            , "_1_3_2_3_reporte_actuaciones_desplego_DCF_omision_informacion_entidad", $$v)},expression:"\n          mainData.step_1_3\n            ._1_3_2_3_reporte_actuaciones_desplego_DCF_omision_informacion_entidad\n        "}}):_vm._e(),(
          _vm.mainData.step_1_3
            ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor ==
          1
        )?_c('BaseRadio',{ref:"tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad",staticClass:"mt-10 mb-10",attrs:{"name":"tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad","label":"¿Se tienen decisiones a favor del consumidor no aceptadas por la entidad?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1323_tn')},model:{value:(
          _vm.mainData.step_1_3
            ._1_3_2_3_tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad
        ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
            , "_1_3_2_3_tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad", $$v)},expression:"\n          mainData.step_1_3\n            ._1_3_2_3_tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad\n        "}}):_vm._e(),(
          _vm.mainData.step_1_3
            ._1_3_2_3_tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad ==
          1
        )?_c('div',[_c('ProductReasonsWithTableField',{ref:"pr_table_1_3_2_3_tn",attrs:{"name":"pr_table_1_3_2_3_tn","company_id":_vm.company.company_id,"labelTotalCounter":'Número de decisiones a favor del consumidor no aceptadas por la entidad',"labelReasonsCounter":'Número decisiones a favor del consumidor no aceptadas por la entidad según el motivo seleccionado',"labelProductCounter":'Número decisiones a favor del consumidor no aceptadas por la entidad según el producto seleccionado',"products_reasons":_vm.mainData.step_1_3._1_3_2_3_tn_producto_motivo,"total-counter":_vm.mainData.step_1_3._1_3_2_3_tn_producto_motivo_counter,"hideInstanceCounter":true,"soloPreview":_vm.soloPreview},on:{"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_tn_producto_motivo", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_tn_producto_motivo_counter", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_tn_producto_motivo_counter", $event)}}}),_c('BaseTextarea',{ref:"describe_step_1323_tn",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_step_1323-tn","label":"Emita breve concepto sobre los pronunciamientos a favor del consumidor financiero no aceptados por la entidad, conforme al impacto generado en los clientes, usuarios y/o potenciales clientes, de cara al riesgo de conductas. (Por favor no incluir casos ni quejas individuales) ","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
            _vm.mainData.step_1_3
              ._1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_no_aceptados_entidad
          ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
              , "_1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_no_aceptados_entidad", $$v)},expression:"\n            mainData.step_1_3\n              ._1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_no_aceptados_entidad\n          "}})],1):_vm._e(),(
          _vm.mainData.step_1_3
            ._1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor ==
          1
        )?_c('BaseRadio',{ref:" tiene_decisiones_a_favor_consumidor_aceptadas_entidad",staticClass:"mt-10 mb-10",attrs:{"name":"tiene_decisiones_a_favor_consumidor_aceptadas_entidad","label":"¿Se tienen decisiones a favor del consumidor aceptadas por la entidad? ","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1323_t')},model:{value:(
          _vm.mainData.step_1_3
            ._1_3_2_3_tiene_decisiones_a_favor_consumidor_aceptadas_entidad
        ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
            , "_1_3_2_3_tiene_decisiones_a_favor_consumidor_aceptadas_entidad", $$v)},expression:"\n          mainData.step_1_3\n            ._1_3_2_3_tiene_decisiones_a_favor_consumidor_aceptadas_entidad\n        "}}):_vm._e(),(
          _vm.mainData.step_1_3
            ._1_3_2_3_tiene_decisiones_a_favor_consumidor_aceptadas_entidad ==
          1
        )?_c('div',[_c('ProductReasonsWithTableField',{ref:"pr_table_1_3_2_3_t",attrs:{"name":"pr_table_1_3_2_3_t","company_id":_vm.company.company_id,"labelTotalCounter":'Número decisiones a favor del consumidor aceptadas',"labelReasonsCounter":'Número decisiones a favor del consumidor aceptadas por la entidad según el motivo seleccionado',"labelProductCounter":'Número decisiones a favor del consumidor aceptadas por la entidad según el producto seleccionado',"instance-counter":_vm.mainData.step_1_3._1_3_2_3_t_instance_counter,"products_reasons":_vm.mainData.step_1_3._1_3_2_3_t_producto_motivo,"hideInstanceCounter":true,"total-counter":_vm.mainData.step_1_3._1_3_2_3_t_producto_motivo_counter,"soloPreview":_vm.soloPreview},on:{"update:instanceCounter":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_t_instance_counter", $event)},"update:instance-counter":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_t_instance_counter", $event)},"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_t_producto_motivo", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_t_producto_motivo_counter", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_2_3_t_producto_motivo_counter", $event)}}}),_c('BaseTextarea',{ref:"describe_step_1323_t",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_step_1323-t","label":"Emita breve concepto sobre los pronunciamientos a favor del consumidor financiero aceptados por la entidad, conforme al impacto generado en los clientes, usuarios y/o potenciales clientes. (Por favor no incluir casos ni quejas individuales.)","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
            _vm.mainData.step_1_3
              ._1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_aceptados_entidad
          ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
              , "_1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_aceptados_entidad", $$v)},expression:"\n            mainData.step_1_3\n              ._1_3_2_3_emita_pronunciamientos_a_favor_consumidor_financiero_aceptados_entidad\n          "}})],1):_vm._e()],1):_vm._e(),_c('h2',{staticClass:"mt-10 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.3.2.4 Pronunciamientos del DCF de interés general ")]),_c('BaseRadio',{ref:"período_reportado_emitió_pronunciamientos_interés_general",staticClass:"mt-10 mb-10",attrs:{"name":"período_reportado_emitió_pronunciamientos_interés_general","label":"¿En el período reportado, la DCF emitió pronunciamientos de interés general?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1324')},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general\n      "}}),(
        _vm.mainData.step_1_3
          ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        2
      )?_c('BaseTextarea',{ref:"describe_1.2.52",staticClass:"mt-10",attrs:{"id":"describe_1.2.52","label":"Describa por qué la DCF no emitió pronunciamientos de interés general.","placeholder":"","maxlength":"500","disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3._1_3_1_describa_situaciones_no_interes_general
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3, "_1_3_1_describa_situaciones_no_interes_general", $$v)},expression:"\n        mainData.step_1_3._1_3_1_describa_situaciones_no_interes_general\n      "}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        1
      )?_c('BaseInput',{ref:"total_pronunciamientos_interés_general_emitidos_DCF",staticClass:"mb-10",attrs:{"label":"Número total de pronunciamientos de interés general emitidos por el DCF","required":true,"type":'number',"numeric":true,"disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_4_total_pronunciamientos_interes_general_emitidos_DCF
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_4_total_pronunciamientos_interes_general_emitidos_DCF", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_4_total_pronunciamientos_interes_general_emitidos_DCF\n      "}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        1
      )?_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_1_2",attrs:{"name":"pr_table_1_2_1_2","company_id":_vm.company.company_id,"labelReasonsCounter":'Número de pronunciamientos de interés general emitidos por el DCF según el motivo seleccionado',"labelProductCounter":'Número de pronunciamientos de interés general emitidos por el DCF según el producto seleccionado',"products_reasons":_vm.mainData.step_1_3._1_3_4_producto_motivo,"hideTopInputs":true,"soloPreview":_vm.soloPreview},on:{"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_3_4_producto_motivo", $event)}}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        1
      )?_c('BaseTextarea',{ref:"describe_step_1324_1",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_step_1324_1","label":"Relacione y describa brevemente los pronunciamientos que el DCF, a su juicio, considere de interés general.(Por favor omita nombre y datos identificatorios del consumidor financiero para garantizar la reserva de los mismos. Tampoco realice transcripciones textuales de las respectivas decisiones.)","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF_juicio
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF_juicio", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF_juicio\n      "}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general ==
        1
      )?_c('BaseRadio',{ref:"conocimiento_entidad_pronunciamientos_interés_general_DCF",staticClass:"mt-10 mb-10",attrs:{"name":"conocimiento_entidad_pronunciamientos_interés_general_DCF","label":"¿Puso en conocimiento de la entidad los pronunciamientos de interés general que emitió la DCF?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1324_1')},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_4_conocimiento_entidad_pronunciamientos_interes_general_emitio_DCF
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_4_conocimiento_entidad_pronunciamientos_interes_general_emitio_DCF", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_4_conocimiento_entidad_pronunciamientos_interes_general_emitio_DCF\n      "}}):_vm._e(),(
        _vm.mainData.step_1_3
          ._1_3_2_4_conocimiento_entidad_pronunciamientos_interes_general_emitio_DCF ==
        1
      )?_c('BaseTextarea',{ref:"describe_step_1324",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_step_1324","label":"En caso afirmativo, por favor indique si tiene conocimiento de alguna actividad desplegada por la entidad al respecto","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
        _vm.mainData.step_1_3
          ._1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF
      ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_3
          , "_1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF", $$v)},expression:"\n        mainData.step_1_3\n          ._1_3_2_4_relacione_describa_brevemente_pronunciamientos_DCF\n      "}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }