<script>
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import ProductReasonsField from "@/feature/annual_report_v2/presentation/components/ProductReasonsField";
import {merge} from "lodash";
import jOptions from "@/feature/annual_report/domain/entities/justification_options";
import exOptions from "@/feature/annual_report/domain/entities/exclutions_options";
import TemplateProductInputs from "@/feature/annual_report/presentation/views/components/template_product_inputs.vue";

export default {
  name: "steps",
  components: {TemplateProductInputs, ProductReasonsField, Accordion},
  data: () => ({
    current_step: 1,
    document_id: null,
    mainData:{
        step_1_1: {
          DCF_recibio_quejas_consumidores_contra_entidad_periodo_reportado: null,
          producto_motivo: [],
        },
        step_1_2: {
          DCF_finalizo_quejas_periodo_reportado: null,
          step_1_2_1: {
            _1_2_1_quejas_terminadas_rectificacion: null,
            _1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF: null,
            _1_2_1_1_producto_motivo: [],
            _1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF: null,
            _1_2_1_2_producto_motivo: [],
            _1_2_1_3_resuma_situaciones_rectificacion_entidad: null,
          },
          step_1_2_2: {
            _1_2_2_quejas_terminadas_desistimiento: null,
            _1_2_2_1_total_quejas_terminadas_por_desistimiento: null,
            _1_2_2_1_producto_motivo: [],
            _1_2_2_2_indique_desistimiento_consumidores_financieros: null,
          },
          step_1_2_3: {
            _1_2_3_quejas_terminadas_inadmision: null,
            _1_2_3_1_total_quejas_terminadas_por_inadmision: null,
            _1_2_3_total_quejas_terminadas_inadmision: null,
            _1_2_3_1_justificacion_inadmision_DCF: null,
            _1_2_3_1_producto_motivo: [],
            _1_2_3_1_justificacion_inadmision_DCF_descripcion: null,
            _1_2_3_1_causal_exclusion_competencia: null,
            _1_2_3_1_causal_exclusion_competencia_descripcion: null,
            _1_2_3_1_aplica_producto_motivo: null,
            _1_2_3_2_resuma_situaciones_inadmision_DCF: null,
          },
          step_1_2_4: {
            _1_2_4_quejas_terminadas_conciliacion: null,
            _1_2_4_1_total_quejas_terminadas_decision_emitida_DCF: null,
            _1_2_4_1_producto_motivo: [],
          },
          step_1_2_5: {
            _1_2_5_decisiones_DCF_emitidas_favor_entidad: null,
            _1_2_5_total_decision_emitida_favor_entidad: null,
            _1_2_5_producto_motivo: [],
            _1_2_5_resuma_situaciones_decision_favor_entidad: null,
            _1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero: null,
            _1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero: null,
            _1_2_5_1_producto_motivo: [],
            _1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero: null,
            _1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero: null,
            _1_2_5_2_producto_motivo: [],
            _1_2_5_2_resuma_situaciones_decision_pacialmente_favorrable_consumidor_financiero: null,
          },
        },
        step_1_3: {
          _1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificacion_reportado: null,
          _1_3_1_describa_situaciones_DCF_considere_incumplimiento: null,
          _1_3_1_total_decision_emitida_favor_entidad: null,
          _1_3_1_producto_motivo: [],
        }
      },
    form: {
      id: null,
      step_1: {
        step_1_1: {
          DCF_recibio_quejas_consumidores_contra_entidad_periodo_reportado: null,
          producto_motivo: [],
        },
        step_1_2: {
          DCF_finalizo_quejas_periodo_reportado: null,
          step_1_2_1: {
            _1_2_1_quejas_terminadas_rectificacion: null,
            _1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF: null,
            _1_2_1_1_producto_motivo: [],
            _1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF: null,
            _1_2_1_2_producto_motivo: [],
            _1_2_1_3_resuma_situaciones_rectificacion_entidad: null,
          },
          step_1_2_2: {
            _1_2_2_quejas_terminadas_desistimiento: null,
            _1_2_2_1_total_quejas_terminadas_por_desistimiento: null,
            _1_2_2_1_producto_motivo: [],
            _1_2_2_2_indique_desistimiento_consumidores_financieros: null,
          },
          step_1_2_3: {
            _1_2_3_quejas_terminadas_inadmision: null,
            _1_2_3_1_total_quejas_terminadas_por_inadmision: null,
            _1_2_3_total_quejas_terminadas_inadmision: null,
            _1_2_3_1_justificacion_inadmision_DCF: null,
            _1_2_3_1_producto_motivo: [],
            _1_2_3_1_justificacion_inadmision_DCF_descripcion: null,
            _1_2_3_1_causal_exclusion_competencia: null,
            _1_2_3_1_causal_exclusion_competencia_descripcion: null,
            _1_2_3_1_aplica_producto_motivo: null,
            _1_2_3_2_resuma_situaciones_inadmision_DCF: null,
          },
          step_1_2_4: {
            _1_2_4_quejas_terminadas_conciliacion: null,
            _1_2_4_1_total_quejas_terminadas_decision_emitida_DCF: null,
            _1_2_4_1_producto_motivo: [],
          },
          step_1_2_5: {
            _1_2_5_decisiones_DCF_emitidas_favor_entidad: null,
            _1_2_5_total_decision_emitida_favor_entidad: null,
            _1_2_5_producto_motivo: [],
            _1_2_5_resuma_situaciones_decision_favor_entidad: null,
            _1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero: null,
            _1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero: null,
            _1_2_5_1_producto_motivo: [],
            _1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero: null,
            _1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero: null,
            _1_2_5_2_producto_motivo: [],
            _1_2_5_2_resuma_situaciones_decision_pacialmente_favorrable_consumidor_financiero: null,
          },
        },
        step_1_3: {
          _1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificacion_reportado: null,
          _1_3_1_describa_situaciones_DCF_considere_incumplimiento: null,
          _1_3_1_total_decision_emitida_favor_entidad: null,
          _1_3_1_producto_motivo: [],
        }
      },
      step_2: {
        dcf_atendio_solicitudes: null,
        numero_total_peticiones: null,
        paso_2_1: {
          numero_total_peticiones: null,
          productos_motivos: [],
          descripcion: null,
        },
        paso_2_2: {
          numero_total_peticiones: null,
          descripcion: null,
        },
        paso_2_3: {
          numero_total_peticiones: null,
          descripcion: null,
        },
      },
      step_3: {
        descripcion: null,
        optInfraestructura: null,
        optInfraestructuraDetail: null,
        dcf_recibio_solicitudes: null,
        numero_total_solicitudes: null,
        productos_motivos: [],
        paso_3_1: {

        },
        paso_3_2: {

        },
        paso_3_3: {

        },
        paso_3_4: {

        },
      },
      step_4: {},
    },
    options: {
      justificationOptions: jOptions,
      exclutionOptions: exOptions,
      oYesOrNot: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      doOrDoNot: [
        { id: 1, name: "Aplica"},
        { id: 2, name: "No Aplica"},
      ],
      oInfra: [
        { id: "1", name: "1. Propios de la DCF" },
        { id: "2", name: "2. Por convenio con un tercero o centro de conciliación" },
      ],
      oNoAsistio: [
        { id: "1", name: "1. No asistió el Consumidor Financiero." },
        { id: "2", name: "2. No asistió la Entidad Vigilada (EV)." },
        { id: "3", name: "3. Ninguna de las partes no asistió." },
      ],
      oApplyProductReason: [
        { id: true, name: "Aplica" },
        { id: false, name: "No aplica" },
      ],
      oJustInadmision: [
        { id: "1", labelName: "1) Causal legal de exclusión de competencia" },
        { id: "2", labelName: "2) Se refiere a otra entidad vigilada" },
        { id: "3", labelName: "3) Solicitud incompleta, no clara, lenguaje soez o agresivo" },
        { id: "4", labelName: "4) Otra" },
      ],
      oTipoJustInCausaLegal:[
        { id: "1", labelName: "a. No corresponde al giro ordinario de las operaciones de la entidad" },
        { id: "2", labelName: "b. Se refiere al vínculo laboral entre la entidad y sus empleados o contratistas" },
        { id: "3", labelName: "c. Se deriva de condición de accionista de la entidad" },
        { id: "4", labelName: "d. Se refiere al reconocimiento de las prestaciones y de las pensiones de invalidez, de vejez y de sobrevivientes" },
        { id: "5", labelName: "e. Se encuentra en trámite judicial o arbitral o hayan sido resueltas en estas vías" },
        { id: "6", labelName: "f. Corresponde a la decisión sobre la prestación de un servicio o producto" },
        { id: "7", labelName: "g. Se refieren a hechos sucedidos con tres (3) años o más de anterioridad" },
        { id: "8", labelName: "h. Han sido objeto de decisión previa por parte del Defensor por tener mismos hechos y afectar a las mismas partes" },
        { id: "9", labelName: "i. Cuantía supera los cien (100) SMLMV" },
        { id: "10", labelName: "j. Otra" }
      ]
    },
    showStep: {
      step_1231_1: false,
      step_1231_4: false,
      step_1231_1_j: false,
    }
  }),
  created() {
    this.document_id = this.$route.params.id;
    this.getData();
  },
  methods: {
    getData() {
      AnnualReportServices
        .getAnualReport(this.$route.params.id)
        .then((response) => {
          merge(this.form, response.data[this.document_id])
          this.form.id = this.document_id;
          console.log("🚀 -> getData -> this.form", this.form)
        })
        .catch((error) => {
          console.log("🚀 -> getData -> error", error);
        });
    },
    onChangeSelect(value, key) {
      if (key) {
        this.showStep[key] = true
        return;
      }
      if (value.id === 1) {
        this.showStep.step_1231_1 = value.boolValue;
        this.showStep.step_1231_4 = false;
        this.showStep.step_1231_1_j = false;
      }
      if (value.id === 4) {
        this.showStep.step_1231_4 = value.boolValue;
        this.showStep.step_1231_1_j = false;
        this.showStep.step_1231_1 = false;
      }
    },
  }
}
</script>

<template>
  <div class="w-full">
          <!--  **************************************  START STEP 1  *********************************************  -->
          <Accordion
            open
            class="px-0"
            header="1. De la función de atención y resolución de quejas"
          >
            <div class="w-full">
              <!-- Start Step 1.1 -->
              <BaseRadio
                label="¿El DCF recibió quejas de los consumidores financieros contra la entidad en el período reportado?"
                required="true"
                class="col-span-2"
                :radioOptions="options.oYesOrNot"
                v-model="form.step_1.step_1_1.DCF_recibio_quejas_consumidores_contra_entidad_periodo_reportado"
              />
              <div class="w-full mt-10 mb-10">
                <Accordion
                  :open="form.step_1.step_1_1.DCF_recibio_quejas_consumidores_contra_entidad_periodo_reportado === 1"
                  class="px-0"
                  header="1.1 Reporte estadístico de quejas recibidas por parte del DCF"
                >
                  <div class="w-full">
                    <ProductReasonsField
                      url-get-counter="?insta_recepcion_id=3"
                      :total-counter.sync="form.step_1.step_1_1.total_quejas_recibidas_DCF"
                      :products_reasons.sync="form.step_1.step_1_1.producto_motivo"
                      label-product-counter="Número de quejas recibidas sobre producto seleccionado"
                      label-reason-counter="Número de quejas recibidas sobre motivo seleccionado"
                      label-total-counter="Número total de quejas recibidas por la DCF"
                      label-instance-counter="Número de quejas registradas instancia DCF"
                    />
                  </div>
                </Accordion>
              </div>
              <!-- Ends Step 1.1 -->
              <!-- Start Step 1.2 -->
              <div class="w-full mt-10 mb-10">
                <Accordion
                  open
                  header="1.2 Reporte de estadísticas de quejas finalizadas"
                >
                  <BaseRadio
                    name="ar_type_12"
                    label="¿El DCF finalizó quejas durante el período reportado? (tenga en cuenta todas las quejas terminadas por rectificación, desistimiento, inadmisión y decisión emitida por el DCF)"
                    :radioOptions="options.oYesOrNot"
                    required="true" class="col-span-2 mb-10"
                    v-model="form.step_1.step_1_2.DCF_finalizo_quejas_periodo_reportado"
                  />
                  <div v-if="form.step_1.step_1_2.DCF_finalizo_quejas_periodo_reportado === 1">
                    <!-- Step 1.2.1 -->
                    <div class="w-full mt-10 mb-10">
                      <Accordion
                        open
                        header="1.2.1 Quejas terminadas por rectificación por parte de la entidad"
                      >
                        <BaseRadio
                          name="step_121"
                          label="¿Se tienen quejas terminadas por rectificación?"
                          :radioOptions="options.oYesOrNot"
                          :required="true"
                          class="col-span-2 mb-10"
                          v-model="form.step_1.step_1_2.step_1_2_1._1_2_1_quejas_terminadas_rectificacion"
                        />
                        <div v-if="form.step_1.step_1_2.step_1_2_1._1_2_1_quejas_terminadas_rectificacion === 1" class="mt-10">
                          <ProductReasonsField
                            url-get-counter="?insta_recepcion_id=3&rectificacion_queja_id=1"
                            :total-counter.sync="form.step_1.step_1_2.step_1_2_1._1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF"
                            :products_reasons.sync="form.step_1.step_1_2.step_1_2_1._1_2_1_1_producto_motivo"
                            label-product-counter="Número de quejas terminadas por rectificación según producto seleccionado"
                            label-reasons-counter="Número de quejas terminadas por rectificación según motivo seleccionado"
                            label-total-counter="1.2.1.1 Número total de quejas terminadas por rectificación de la entidad vigilada antes de la decisión del DCF"
                            label-instance-counter="Número de quejas registradas por terminación por rectificación de la entidad vigilada antes de la decisión del DCF"
                          />
                          <ProductReasonsField
                            class="mt-10 mb-10"
                            url-get-counter="?insta_recepcion_id=3&rectificacion_queja_id=3"
                            :total-counter.sync="form.step_1.step_1_2.step_1_2_1._1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF"
                            :products_reasons.sync="form.step_1.step_1_2.step_1_2_1._1_2_1_2_producto_motivo"
                            label-product-counter="Número de quejas terminadas por rectificación según producto seleccionado"
                            label-reasons-counter="Número de quejas terminadas por rectificación según motivo seleccionado"
                            label-total-counter="1.2.1.2. Número total de quejas terminadas por rectificación de la entidad vigilada después de la decisión del DCF"
                            label-instance-counter="Número de quejas registradas por terminación por rectificación de la entidad vigilada después de la decisión del DCF"
                          />
                          <BaseTextarea
                            class="col-start-1 col-span-2"
                            id="describe_1.2.1.3"
                            label="1.2.1.3. Resuma las situaciones objeto de rectificación por parte de la entidad, indicando la forma en que esta procede (priorice lo más representativo a juicio de la DCF, por impacto en los consumidores financieros o requerir acciones de mejora que ameriten el reporte a la SFC). Por favor no incluir casos ni quejas individuales."
                            placeholder=""
                            maxlength="1000"
                            v-model="form.step_1.step_1_2.step_1_2_1._1_2_1_3_resuma_situaciones_rectificacion_entidad"
                          />
                        </div>
                      </Accordion>
                    </div>
                    <!-- Ends Step 1.2.1 -->
                    <!-- Step 1.2.2 -->
                    <div class="w-full mt-10 mb-10">
                      <Accordion
                        open
                        header="1.2.2 Quejas terminadas por desistimiento"
                      >
                        <BaseRadio
                          name="step_122"
                          label="¿Se tienen quejas terminadas por desistimiento?"
                          :radioOptions="options.oYesOrNot"
                          required="true"
                          class="col-span-2 mb-10"
                          v-model="form.step_1.step_1_2.step_1_2_2._1_2_2_quejas_terminadas_desistimiento"
                        />
                        <div v-if="form.step_1.step_1_2.step_1_2_2._1_2_2_quejas_terminadas_desistimiento === 1" class="mt-10">
                          <ProductReasonsField
                            url-get-counter="?insta_recepcion_id=3&desistimiento_queja_id=1"
                            :total-counter.sync="form.step_1.step_1_2.step_1_2_2._1_2_2_1_total_quejas_terminadas_por_desistimiento"
                            :products_reasons.sync="form.step_1.step_1_2.step_1_2_2._1_2_2_1_producto_motivo"
                            label-product-counter="Número de quejas terminadas por desistimiento según producto seleccionado"
                            label-reasons-counter="Número de quejas terminadas por desistimiento según motivo seleccionado"
                            label-total-counter="1.2.2.1 Número total de quejas terminadas por desistimiento"
                            label-instance-counter="Número de quejas registradas terminadas por desistimiento"
                          />
                          <BaseTextarea
                            class="col-start-1 col-span-2"
                            id="describe_1.2.2.2"
                            label="1.2.2.2 Indique las razones de desistimiento informadas por los consumidores financieros, priorizando las que la DCF considere más representativas"
                            placeholder=""
                            maxlength="2000"
                            v-model="form.step_1.step_1_2.step_1_2_2._1_2_2_2_indique_desistimiento_consumidores_financieros"
                          />
                        </div>
                      </Accordion>
                    </div>
                    <!-- Ends Step 1.2.2 -->
                    <!-- Step 1.2.3 -->
                    <div class="w-full mt-10 mb-10">
                      <Accordion
                        open
                        header="1.2.3 Quejas terminadas por inadmisión"
                      >
                        <BaseRadio
                          name="step_123"
                          label="¿Se tienen quejas terminadas por inadmisión?"
                          :radioOptions="options.oYesOrNot"
                          required="true"
                          class="col-span-2 mb-10"
                          v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_quejas_terminadas_inadmision"
                        />
                        <div v-if="form.step_1.step_1_2.step_1_2_3._1_2_3_quejas_terminadas_inadmision === 1" class="mt-10">
                          <div class="grid grid-cols-2 gap-x-8 mb-10">
                            <BaseInput
                              type="text"
                              label="1.2.3 Número total de quejas terminadas por inadmisión" :required="true"
                              v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_total_quejas_terminadas_inadmision"
                            />
                          </div>
                          <BaseSelect
                            label="1.2.3.1 Justificación de la inadmisión por parte del DCF"
                            class="mt-10 mb-10"
                            @response="(value) => onChangeSelect(value)"
                            :selectOptions="options.justificationOptions"
                            v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_1_justificacion_inadmision_DCF"
                          />
                          <BaseTextarea
                            v-if="showStep.step_1231_4"
                            class="col-start-1 col-span-2"
                            id="describe_1.2.3.1_4"
                            label="Descripción"
                            placeholder=""
                            maxlength="50"
                            v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_1_justificacion_inadmision_DCF_descripcion"
                          />
                          <BaseSelect
                            v-if="showStep.step_1231_1"
                            label="1. Causal legal de exclusión de competencia"
                            @response="(value) => onChangeSelect(value, 'step_1231_1_j')"
                            :selectOptions="options.exclutionOptions"
                            class="mb-10"
                            v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_1_causal_exclusion_competencia"
                          />
                          <BaseTextarea
                            v-if="showStep.step_1231_1_j"
                            class="col-start-1 col-span-2"
                            ref="describe_1.2.3.1_1_j"
                            id="describe_1.2.3.1_1_j"
                            label="Descripción"
                            placeholder=""
                            maxlength="50"
                            v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_1_causal_exclusion_competencia_descripcion"
                          />
                          <BaseRadio
                            v-if="showStep.step_1231_1"
                            ref="step_1231_1"
                            name="step_1231_1"
                            label="¿Aplica producto y motivo?"
                            class="mt-10 mb-10"
                            :radioOptions="options.doOrDoNot"
                            required="true"
                            v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_1_aplica_producto_motivo"
                          />
                          <ProductReasonsField
                            v-if="form.step_1.step_1_2.step_1_2_3._1_2_3_1_aplica_producto_motivo === 1"
                            url-get-counter="?insta_recepcion_id=3&inadmision_queja_id=1"
                            :total-counter.sync="form.step_1.step_1_2.step_1_2_3._1_2_3_1_total_quejas_terminadas_por_inadmision"
                            :products_reasons.sync="form.step_1.step_1_2.step_1_2_3._1_2_3_1_producto_motivo"
                            label-product-counter="Número de quejas terminadas por inadmisión según producto seleccionado"
                            label-reasons-counter="Número de quejas terminadas por inadmisión según motivo seleccionado"
                            label-total-counter=""
                            />
                          <BaseTextarea
                            class="mt-10 col-start-1 col-span-2"
                            id="describe_1.2.2.2"
                            label="1.2.3.2 Resuma las situaciones que hayan sido objeto de inadmisión por parte de la DCF, priorizando las más representativas. No incluir casos ni situaciones particulares"
                            placeholder="" maxlength="2000"
                            v-model="form.step_1.step_1_2.step_1_2_3._1_2_3_2_resuma_situaciones_inadmision_DCF" />
                        </div>
                      </Accordion>
                    </div>
                    <!-- Ends Step 1.2.3 -->
                    <!-- Step 1.2.4 -->
                    <div class="w-full mt-10 mb-10">
                      <Accordion
                        open
                        class="w-full"
                        header="1.2.4 Quejas terminadas por decisión emitida por el DCF"
                      >
                        <BaseRadio
                          name="step_124"
                          label="¿Se tienen quejas terminadas por conciliación?"
                          :radioOptions="options.oYesOrNot"
                          required="true"
                          class="col-span-2 mb-10"
                          v-model="form.step_1.step_1_2.step_1_2_4._1_2_4_quejas_terminadas_conciliacion"
                        />
                        <div v-if="form.step_1.step_1_2.step_1_2_4._1_2_4_quejas_terminadas_conciliacion === 1" class="mt-10">
                          <ProductReasonsField
                            url-get-counter="?insta_recepcion_id=3&admision_id=2"
                            hide-instance-counter
                            :total-counter.sync="form.step_1.step_1_2.step_1_2_4._1_2_4_1_total_quejas_terminadas_decision_emitida_DCF"
                            :products_reasons.sync="form.step_1.step_1_2.step_1_2_4._1_2_4_1_producto_motivo"
                            label-product-counter="Número de quejas terminadas por decisión emitida por el DCF según producto seleccionado"
                            label-reasons-counter="Número de quejas terminadas por decisión emitida por el DCF según motivo seleccionado"
                            label-total-counter="1.2.4.1 Número total de quejas terminadas por decisión emitida por el DCF"
                            label-instance-counter="Número de quejas registradas terminadas por decisión emitida por el DCF"
                          />

                          <h2 class="mt-20  text-[#5C5C5C] text-lg font-semibold text-left">
                            1.2.5 Decisiones del DCF emitidas a favor de la entidad
                          </h2>

                          <BaseRadio
                            name="step_125"
                            label=""
                            :radioOptions="options.doOrDoNot"
                            required="true"
                            class="mt-20 mb-10"
                            v-model="form.step_1.step_1_2.step_1_2_5._1_2_5_decisiones_DCF_emitidas_favor_entidad"
                          />
                          <template
                            v-if="form.step_1.step_1_2.step_1_2_5._1_2_5_decisiones_DCF_emitidas_favor_entidad === 1"
                          >
                            <ProductReasonsField
                              url-get-counter="?insta_recepcion_id=3&a_favor_de_id=3"
                              hide-instance-counter
                              :total-counter.sync="form.step_1.step_1_2.step_1_2_5._1_2_5_total_decision_emitida_favor_entidad"
                              :products_reasons.sync="form.step_1.step_1_2.step_1_2_5._1_2_5_producto_motivo"
                              label-product-counter="Número de quejas terminadas por decisión emitida por el DCF a favor de la entidad según producto seleccionado"
                              label-reasons-counter="Número de quejas terminadas por decisión emitida por el DCF a favor de la entidad según motivo seleccionado"
                              label-total-counter="Número total de decisiones emitidas a favor de la entidad"
                              label-instance-counter="Número total registrado de decisiones emitidas a favor de la entidad"
                            />
                            <BaseTextarea
                              class="mt-10"
                              id="describe_1.2.5"
                              label="Resuma las situaciones objeto de decisión a favor de la entidad, destacando aquellas buenas prácticas que la defensoría haya identificado en el proceder de la vigilada. Sin hacer referencia a casos particulares"
                              placeholder=""
                              maxlength="1000"
                              v-model="form.step_1.step_1_2.step_1_2_5._1_2_5_resuma_situaciones_decision_favor_entidad"
                            />
                          </template>
                          <div class="w-full">
                            <h2 class="mt-20  text-[#5C5C5C] text-lg font-semibold text-left">
                              1.2.5.1 Decisiones emitidas a favor del consumidor financiero (total y parcialmente favorables)
                            </h2>
                          </div>
                          <BaseRadio
                            name="step_1251"
                            label="Decisiones del DCF totalmente favorables al consumidor financiero"
                            :radioOptions="options.doOrDoNot"
                            required="true"
                            class="mt-20 mb-10"
                            v-model="form.step_1.step_1_2.step_1_2_5._1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero"
                          />
                          <template
                            v-if="form.step_1.step_1_2.step_1_2_5._1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero === 1"
                          >
                            <ProductReasonsField
                              url-get-counter="?insta_recepcion_id=3&a_favor_de_id=1"
                              hide-instance-counter
                              :total-counter.sync="form.step_1.step_1_2.step_1_2_5._1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero"
                              :products_reasons.sync="form.step_1.step_1_2.step_1_2_5._1_2_5_1_producto_motivo"
                              label-product-counter="Número total de decisiones totalmente favorables al consumidor financiero según producto seleccionado"
                              label-reasons-counter="Número total de decisiones totalmente favorables al consumidor financiero según motivo seleccionado"
                              label-total-counter="Número total de decisiones totalmente favorables al consumidor financiero"
                              label-instance-counter="Número total registrado de decisiones totalmente favorables al consumidor financiero"
                            />
                            <BaseTextarea
                              class="mt-10"
                              id="describe_1.2.5"
                              label="Resuma las situaciones objeto de decisión a favor de la entidad, destacando aquellas buenas prácticas que la defensoría haya identificado en el proceder de la vigilada. Sin hacer referencia a casos particulares"
                              placeholder=""
                              maxlength="1000"
                              v-model="form.step_1.step_1_2.step_1_2_5._1_2_5_resuma_situaciones_decision_favor_entidad"
                            />
                          </template>
                          <BaseRadio
                            name="step_1252"
                            label="1.2.5.2 Decisiones del DCF parcialmente favorables al consumidor financiero"
                            :radioOptions="options.doOrDoNot"
                            required="true"
                            class="mt-20 mb-10"
                            v-model="form.step_1.step_1_2.step_1_2_5._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero"
                          />
                          <template
                            v-if="form.step_1.step_1_2.step_1_2_5._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero === 1"
                          >
                            <ProductReasonsField
                              url-get-counter="?insta_recepcion_id=3&a_favor_de_id=2"
                              hide-instance-counter
                              :total-counter.sync="form.step_1.step_1_2.step_1_2_5._1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero"
                              :products_reasons.sync="form.step_1.step_1_2.step_1_2_5._1_2_5_2_producto_motivo"
                              label-product-counter="Número total de decisiones parcialmente favorables según producto seleccionado"
                              label-reasons-counter="Número total de decisiones parcialmente favorables según motivo seleccionado"
                              label-total-counter="Número total de decisiones parcialmente favorables al consumidor financiero"
                              label-instance-counter="Número total de decisiones parcialmente favorables registradas"
                            />
                            <BaseTextarea
                              class="mt-10"
                              id="describe_1.2.52"
                              label="Resuma la forma en que la entidad procede frente a las situaciones objeto de decisión parcialmente a favor del consumidor financiero (priorice lo más representativo por impacto en los consumidores, o que requiera acciones de mejora). Por favor no incluir casos ni quejas individuales"
                              placeholder=""
                              maxlength="1000"
                              v-model="form.step_1.step_1_2.step_1_2_5._1_2_5_2_resuma_situaciones_decision_pacialmente_favorrable_consumidor_financiero"
                            />
                          </template>
                        </div>
                      </Accordion>
                    </div>
                    <!-- Ends Step 1.2.4 -->
                  </div>
                </Accordion>
              </div>
              <!-- Ends Step 1.2 -->
              <!-- Start Step 1.3 -->
              <div class="w-full mt-10 mb-10">
                <Accordion
                  open
                  header="1.3 Relacionamiento entre la entidad y el defensor"
                >
                  <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
                    1.3.1 Reporte reincidencias
                  </h2>
                  <BaseRadio
                    name="step_131"
                    label="Se presentaron situaciones de incumplimiento parcial o total por parte de la entidad con posterioridad a los ajustes o a la rectificación en el período reportado"
                    :radioOptions="options.oYesOrNot"
                    required="true"
                    class="mt-20 mb-10"
                    v-model="form.step_1.step_1_3._1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificacion_reportado"
                  />
                  <div v-if="form.step_1.step_1_3._1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificacion_reportado === 1">
                    <ProductReasonsField
                      url-get-counter="?insta_recepcion_id=3&rectificacion_queja_id=4'"
                      hide-instance-counter
                      :total-counter.sync="form.step_1.step_1_3._1_3_1_total_decision_emitida_favor_entidad"
                      :products_reasons.sync="form.step_1.step_1_3._1_3_1_producto_motivo"
                      label-product-counter="Número total de reincidencias según producto seleccionado"
                      label-reasons-counter="Número total de reincidencias según motivo seleccionado"
                      label-total-counter="Número total de reincidencias registradas"
                      label-instance-counter="Número total registrado de quejas terminadas por incumplimiento"
                    />
                    <BaseTextarea
                      class="mt-10"
                      id="describe_1.2.52"
                      label="Describa las situaciones que la DCF considere de incumplimiento (parcial o total) por parte de la entidad vigilada después de haber efectuado, ajustes o rectificaciones, sin hacer referencia a casos particulares."
                      placeholder=""
                      maxlength="500"
                      v-model="form.step_1.step_1_3._1_3_1_describa_situaciones_DCF_considere_incumplimiento"
                    />
                  </div>
                  <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
                    1.3.2 Reporte de situaciones relevantes
                  </h2>

                  <h2 class="mt-10 text-[#5C5C5C] text-lg font-semibold text-left">
                    1.3.2.1 Casos en que la entidad consideró que el DCF carecía de competencia
                  </h2>
                  <!-- TO_DO: Completar paso 1.3 -->
                </Accordion>
              </div>
              <!-- Ends Step 1.3 -->
            </div>
          </Accordion>
        </div>
</template>