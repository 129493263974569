<template>
  <div>
    <div class="px-6 py-2 rounded-t-lg flex flex-cols items-center justify-between cursor-pointer shadow"
      v-on:click="changeOpen">
      <p class="text-[#5C5C5C] text-left text-lg">
        {{ subheader }}
        <b>{{ header }}</b>
      </p>
      <span class="h-5 w-5 m-2 flex justify-center items-center transition" :class="{ 'transform rotate-180': isOpen }">
        <a-icon v-if="!isDelete" type="caret-down" class="text-xl my-auto" />
        <a-icon v-if="isDelete" type="close" class="text-xl my-auto" v-on:click="delet" />
      </span>
    </div>

    <div v-show="isOpen" :class="'px-8 py-8 shadow-xl border rounded-b-xl ' + advanceStyles"
      style="border-top: gray solid 1px">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    header: { type: String, default: "Header Title" },
    disable: { type: Boolean, default: false },
    isDelete: { type: Boolean, default: false },
    subheader: { type: String, default: "" },
    open: { type: Boolean, default: false },
    advanceStyles: {
      type: String,
      default: "",
    },
    change: function (state) {

      this.accordionOpen = state

    },
  },
  data() {
    return {
      accordionOpen: false,
    };
  },
  created() {
    this.accordionOpen = this.open;
  },
  methods: {
    changeOpen() {
      if (!this.disable) {
        this.accordionOpen = !this.accordionOpen;
      }
      else{
        this.$message.warning('Esta opción no está activa');
      }
    },
    delet() {
      this.$emit("deletecomponent");
    }
  },
  watch: {
    open(value) {
      this.accordionOpen = value;
    }
  },
  computed: {
    isOpen() {
      if (!this.disable) {
        return this.accordionOpen
      }
    }
  }
};
</script>
